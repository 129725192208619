import React, { useEffect, useState } from 'react'
import { useSelector, RootStateOrAny } from 'react-redux'
import { useNavigate } from 'react-router'
import CheckoutStripe from '../../components/CheckoutStripe'
import Loading from '../../components/Loading'

const Checkout = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { user } = useSelector((state: RootStateOrAny) => state.user)
  const { planSelected } = useSelector((state: RootStateOrAny) => state.plans)

  useEffect(() => {
    if (!planSelected || !user) {
      navigate('/pricing')
    }
    setLoading(false)
  }, [])

  if (loading) return <Loading text={''} />

  return (
    <div className="content pt-10">
      <CheckoutStripe />
    </div>
  )
}

export default Checkout
