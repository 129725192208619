import React from 'react'

const SectionWithSidebar = ({ sidebar, section }: any) => {
  return (
    <div className="content pd-10">
      <div className="grid grid-cols-5 gap-4 py-10">
        {sidebar}
        {section}
      </div>
    </div>
  )
}

export default SectionWithSidebar
