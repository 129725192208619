// temporary, since there are only a few mock endpoints

import { signInWithEmailAndPassword } from 'firebase/auth'
import getAxios from '../axiosInstance'
import { config } from '../config'
import { auth, FirebaseAuthErrors } from '../firebase'
import { IPricingPlan } from '../Types'

let axios = getAxios()

const plansTransformer = (plans: any) => {
  const result: any = []

  for (const _plan of plans) {
    // eslint-disable-next-line camelcase
    const plan = {
      unitLabel: parseInt(_plan.unit_label),
      name: _plan.name,
      id: _plan.id,
      defaultPriceData: {
        recurring: {
          intervalCount: _plan.default_price_data.recurring.interval_count,
          interval: _plan.default_price_data.recurring.interval,
        },
        currency: _plan.default_price_data.currency,
        id: _plan.default_price_data.id,
        unitAmountDecimal: _plan.default_price_data.unit_amount_decimal,
      },
      shippable: false,
    }
    result.push(plan)
  }

  return result.sort((a, b) => a.defaultPriceData.unitAmountDecimal - b.defaultPriceData.unitAmountDecimal)
}

export const getPlans = async () => {
  const plans: Array<IPricingPlan> = (
    await axios.get(`${config.apiUrl}/plans`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  ).data

  return plansTransformer(plans)
}

export const checkout = async (planId: string) => {
  axios = getAxios()
  try {
    const { data } = await axios.post(`${config.apiUrl}/plans/checkout?provider=stripe`, {
      planId,
    })
    if (!data.result) {
      return { result: false, error: data.message?.toString() || data.error || 'unknown error' }
    }
    return { result: true, clientSecret: data.clientSecret }
  } catch (e) {
    return e.data
  }
}

export const continueCheckout = async () => {
  axios = getAxios()
  try {
    const { data } = await axios.get(`${config.apiUrl}/plans/continue-checkout?provider=stripe`)
    if (!data.result) {
      return { result: false, error: data.message?.toString() || data.error || 'unknown error' }
    }
    return { result: true, clientSecret: data.clientSecret }
  } catch (e) {
    return e.data
  }
}

export const getFreePlan = async () => {
  axios = getAxios()
  try {
    const { data } = await axios.get(`${config.apiUrl}/plans/get-free-plan`)
    if (!data.result) {
      return { result: false, error: data.message?.toString() || data.error || 'unknown error' }
    }
    return { result: true }
  } catch (e) {
    return e.data || { result: false, error: 'unknown error' }
  }
}

export const cancelSubscription = async () => {
  axios = getAxios()
  try {
    const { data } = await axios.delete(`${config.apiUrl}/plans/cancel-subscription?provider=stripe`)
    if (!data.result) {
      return { result: false, error: data.message?.toString() || data.error || 'unknown error' }
    }
    return { result: true }
    location.reload()
  } catch (e) {
    return e.data
  }
}

export const getMe = async () => {
  axios = getAxios()

  try {
    return (
      await axios.get(`${config.apiUrl}/users/me`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    ).data
  } catch (e) {
    return
  }
}

export const register = async (payload: any) => {
  axios = getAxios()
  try {
    return (await axios.post(config.apiUrl + '/users/register', payload)).data
  } catch (e: any) {
    return {
      result: false,
      error: e.data?.error || 'UNKNOWN_ERROR',
    }
  }
}

export const registerFromProvider = async (payload: any, provider: string) => {
  axios = getAxios()
  try {
    return (await axios.post(config.apiUrl + `/users/register/${provider}`, payload)).data
  } catch (e: any) {
    console.error('err register from provider', e)
    return {
      result: false,
      error: e.data?.error || 'UNKNOWN_ERROR',
    }
  }
}

export const login = async (email: string, password: string) => {
  axios = getAxios()
  try {
    await signInWithEmailAndPassword(auth, email, password)
    if (!auth.currentUser) return
    const idToken = await auth.currentUser.getIdToken(true)
    const loginResult = (await axios.post(`${config.apiUrl}/users/login/`, { idToken: idToken })).data
    if (!loginResult.result) return loginResult
    loginResult.token = idToken
    return loginResult
  } catch (e) {
    console.error('err login api', e)
    return {
      result: false,
      error: e.data?.error || FirebaseAuthErrors[e.code] || 'UNKNOWN_ERROR',
    }
  }
}

export const loginFromProvider = async (payload: any, provider: string) => {
  axios = getAxios()
  try {
    const result = await axios.post(`${config.apiUrl}/users/login/${provider}`, payload)
    return result.data
  } catch (e) {
    return {
      result: false,
      error: e.data?.error || 'UNKNOWN_ERROR',
    }
  }
}

export const verifyEmail = async (token: string) => {
  axios = getAxios()
  try {
    return (await axios.post(config.apiUrl + `/users/verify-email/${token}`)).data
  } catch (e: any) {
    // log err
    return {
      result: false,
      error: 'UNKNOWN_ERROR',
    }
  }
}

export const regenerateApiKey = async () => {
  axios = getAxios()
  try {
    const result = await axios.post(`${config.apiUrl}/users/regenerate-apikey`)
    return result.data
  } catch (e) {
    return {
      result: false,
      error: e.data?.error || 'UNKNOWN_ERROR',
    }
  }
}

export const getText = async (data) => {
  axios = getAxios()
  try {
    return (await axios.post(`${config.apiUrl}/ai/`, data)).data
  } catch (e: any) {
    // log err
    return {
      result: false,
      error: 'UNKNOWN_ERROR',
    }
  }
}

export const password = async (newPassword: string) => {
  axios = getAxios()
  try {
    return (await axios.put(`${config.apiUrl}/users/password/`, { newPassword: newPassword })).data
  } catch (e) {
    console.error('err password api', e)
    console.error('e.data', e.data)
    return {
      result: false,
      error: e.data?.error || FirebaseAuthErrors[e.code] || 'UNKNOWN_ERROR',
    }
  }
}

export const resendEmail = async () => {
  axios = getAxios()
  try {
    return (await axios.get(`${config.apiUrl}/users/resend-email/`)).data
  } catch (e) {
    console.error('err resendEmail api', e)
    console.error('e.data', e.data)
    return {
      result: false,
      error: e.data?.error || FirebaseAuthErrors[e.code] || 'UNKNOWN_ERROR',
    }
  }
}
