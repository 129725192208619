import { createSlice } from '@reduxjs/toolkit'

import { auth, provider } from '../../firebase'
import { signInWithPopup } from 'firebase/auth'
import { getMe, login, loginFromProvider, register, registerFromProvider } from '../../api'
import { toast } from 'react-toastify'
import { changingPlan, setPlanSelectedAction } from './plans'
import { getError } from '../../utils/pipes'

const initialState = {
  user: null,
  subscription: null,
  userLoading: false,
  error: false,
  accessToken: null,
  refreshToken: null,
  verifyEmailView: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload
    },
    setSubscription: (state, { payload }) => {
      state.subscription = payload
    },
    setAccessToken: (state, { payload }) => {
      localStorage.setItem('accessToken', payload)
      state.accessToken = payload
    },

    setRefreshToken: (state, { payload }) => {
      localStorage.setItem('refreshToken', payload)
      state.refreshToken = payload
    },
    setUserLoading: (state, { payload }) => {
      state.userLoading = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
    setVerifyEmailView: (state, { payload }) => {
      state.verifyEmailView = payload
    },
    logout: (state) => {
      state.refreshToken = null
      state.accessToken = null
      state.user = null
    },
  },
})

export const { reducer, actions } = userSlice

type User = {
  id: string
  email: string
  firstName: string
  lastName: string
  country?: string
  company?: string
  characters?: {
    value: number
    expiration: number
  }
  apiKey?: string
  picture?: string
  createdAt: number
  verifiedEmail: boolean
}

type CreateUserResponse = {
  result: boolean
  error: string | undefined
  user: User
}

export const setUserAction = (user: any) => (dispatch: any) => {
  dispatch(actions.setUser(user))
}

export const updateUserBeforeChangingPlan = (navigate, plan, t: any) => async (dispatch: any) => {
  dispatch(actions.setUserLoading(true))
  try {
    const user = (await getMe()).user
    dispatch(actions.setUser(user))

    dispatch(setPlanSelectedAction(plan))
    dispatch(changingPlan(navigate, plan, t))
  } catch (e) {
    return
  } finally {
    dispatch(actions.setUserLoading(false))
  }
}

export const getMeAction = () => async (dispatch: any) => {
  dispatch(actions.setUserLoading(true))
  try {
    const user = (await getMe()).user
    dispatch(actions.setUser(user))
  } catch (e) {
    return
  } finally {
    dispatch(actions.setUserLoading(false))
  }
}

export const createUser = (user: any, t: any) => async (dispatch: any) => {
  dispatch(actions.setUserLoading(true))
  const signUpLoadingToast = toast.loading('')

  const createUserResponse: CreateUserResponse = await register(user)
  if (!createUserResponse.result) {
    toastAction.fail(signUpLoadingToast, createUserResponse.error, t)
  } else {
    toastAction.success(signUpLoadingToast, 'Welcome to Turingkit')
    dispatch(actions.setVerifyEmailView(true))
  }
  dispatch(actions.setUserLoading(false))
  return createUserResponse
}

const loginUserError = (dispatch, signInLoadingToast, error, t: any) => {
  toastAction.fail(signInLoadingToast, error, t)
  dispatch(actions.setUserLoading(false))
  return dispatch(actions.setError(error))
}

export const loginUser = (navigate: any, path: any, user: any, t: any) => async (dispatch: any) => {
  const signInLoadingToast = toast.loading('')
  dispatch(actions.setUserLoading(true))
  let loginUserResponse: any
  try {
    loginUserResponse = await login(user.email, user.password)
  } catch (e) {
    return loginUserError(dispatch, signInLoadingToast, 'INVALID_USER', t)
  }
  if (!loginUserResponse.result) {
    return loginUserError(dispatch, signInLoadingToast, loginUserResponse.error, t)
  }
  dispatch(actions.setAccessToken(loginUserResponse.token))
  dispatch(actions.setRefreshToken(loginUserResponse.token))
  dispatch(getMeAction())
  toastAction.success(signInLoadingToast)

  dispatch(actions.setError(false))
  if (path) navigate(path)
}

const toastAction = {
  fail: (loading: any, error: any, t: any) => {
    toast.update(loading, {
      type: 'error',
      render: getError(error, t),
      isLoading: false,
      position: 'top-center',
      autoClose: 4000,
    })
  },
  success: (loading: any, success = '') => {
    toast.update(loading, {
      type: 'success',
      render: success,
      isLoading: false,
      position: 'top-center',
      autoClose: 4000,
    })
  },
}

const signUpFromGoogle = async (googleSignInResponse: any, t: any) => {
  const signUpLoadingToast = toast.loading('')

  const createUserResponse: CreateUserResponse = await registerFromProvider(
    {
      accessToken: googleSignInResponse._tokenResponse.oauthAccessToken,
      idToken: googleSignInResponse.user.accessToken,
    },
    'google'
  )
  if (!createUserResponse.result) {
    toastAction.fail(signUpLoadingToast, createUserResponse.error, t)
  } else {
    toastAction.success(signUpLoadingToast, 'Welcome to Turingkit')
  }
  return createUserResponse
}

const signInFromGoogle = async (googleSignInResponse: any, t: any) => {
  const signUpLoadingToast = toast.loading('')

  const loginUserResponse: CreateUserResponse = await loginFromProvider(
    {
      accessToken: googleSignInResponse._tokenResponse.oauthAccessToken,
      idToken: googleSignInResponse.user.accessToken,
    },
    'google'
  )
  if (!loginUserResponse.result) {
    if (loginUserResponse.error !== 'USER_NOT_FOUND')
      toastAction.fail(signUpLoadingToast, loginUserResponse.error, t)
    else {
      toast.dismiss(signUpLoadingToast)
    }
  }
  return loginUserResponse
}

export const loginGoogle = (navigate: any, isSignUp: boolean, path: any, t: any) => async (dispatch: any) => {
  dispatch(actions.setUserLoading(true))

  try {
    provider.setCustomParameters({ prompt: 'select_account' })
    const googleSignInResponse: any = await signInWithPopup(auth, provider)
    const googleUser = JSON.parse(googleSignInResponse._tokenResponse.rawUserInfo)
    let user: User
    if (isSignUp) {
      const signUpResult: any = await signUpFromGoogle(googleSignInResponse, t)
      if (!signUpResult.result) {
        dispatch(actions.setUserLoading(false))
        return dispatch(actions.setError(signUpResult.error))
      }
      user = signUpResult.user
      path = '/pricing'
    } else {
      // check in the back that the token is correct
      const signInResult = await signInFromGoogle(googleSignInResponse, t)
      if (!signInResult.result && signInResult.error) {
        const signUpResult: any = await signUpFromGoogle(googleSignInResponse, t)
        if (!signUpResult.result) {
          return dispatch(actions.setError(signUpResult.error))
        }
        user = signUpResult.user
        path = '/pricing'
      } else if (!signInResult.result) {
        dispatch(actions.setUserLoading(false))
        return dispatch(actions.setError(signInResult.error))
      }
      if (!user) user = signInResult.user
    }
    dispatch(actions.setAccessToken(googleSignInResponse.user.accessToken))
    dispatch(actions.setRefreshToken(googleSignInResponse.user.stsTokenManager.refreshToken))
    dispatch(actions.setUser({ ...user, picture: googleUser.picture }))

    dispatch(getMeAction())
    dispatch(actions.setUserLoading(false))
    dispatch(actions.setError(false))
    toast.dismiss()
    if (path) navigate(path)
  } catch (err) {
    toast.dismiss()
    dispatch(actions.setError(true))
    dispatch(actions.setUserLoading(false))
  }
}

export const logout = (navigate: any) => (dispatch: any) => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  dispatch(actions.logout())
  if (navigate) navigate('/')
  return
}

export default reducer
