import React from 'react'

const InputOnlyRead = ({ label, value }: any) => {
  return (
    <div className="pb-6 input-default w-full">
      <label className="text-blue-300 dark:text-green-400 text-sm">{label}</label>
      <p>{value}</p>
    </div>
  )
}

export default InputOnlyRead
