import SettingsWrapper from '../SettingsWrapper'

import React, { useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import Loading from '../../Loading'
import Input from '../../base/Input'
import { password } from '../../../api'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getError } from '../../../utils/pipes'
import { useTranslation } from 'react-i18next'

const Security = () => {
  const { user } = useSelector((state: RootStateOrAny) => state.user)
  const [loading, setLoading] = useState(false)
  const [passwordForm, setPasswordForm] = useState<any>({
    newPassword: '',
    confirmNewPassword: '',
  })
  const { t } = useTranslation()

  const setInputValue = (label: string, value: any) => {
    const updatedForm = { ...passwordForm }
    updatedForm[label] = value
    setPasswordForm(updatedForm)
  }

  const submitForm = async () => {
    if (passwordForm.newPassword !== passwordForm.confirmNewPassword) {
      return toast.error("The passwords don't match", {
        position: 'top-center',
      })
    }
    setLoading(true)
    const passwordResult = await password(passwordForm.newPassword)
    if (!passwordResult.result) {
      setLoading(false)
      toast.error(getError(passwordResult.error, t), {
        position: 'top-center',
      })
      return
    }
    setPasswordForm({
      newPassword: '',
      confirmNewPassword: '',
    })
    const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME || 'accessToken'

    localStorage.setItem(TOKEN_NAME, passwordResult.token)
    setLoading(false)
    toast.info('Password updated successfuly', {
      position: 'top-center',
    })
  }
  if (!loading && !user) {
    return (
      <div className="col-span-3">
        <p>{'Please, sign in to view your details'}</p>
      </div>
    )
  }
  if (!user || loading) {
    return (
      <div className="content py-10">
        <Loading text="" />
      </div>
    )
  }

  return (
    <SettingsWrapper title={'Change Password'}>
      <ToastContainer theme="dark" autoClose={3000} />

      <div className="text-sm grid grid-cols-3">
        <div>
          <Input
            value={passwordForm.newPassword}
            setValue={setInputValue}
            key="newPassword"
            name="newPassword"
            type="password"
            label="New Password"
            placeholder="New Password"
          />
          <Input
            value={passwordForm.confirmNewPassword}
            setValue={setInputValue}
            key="confirmNewPassword"
            name="confirmNewPassword"
            type="password"
            label="Confirm New Password"
            placeholder="Confirm New Password"
          />
          <button onClick={submitForm} type="button" className="btn">
            {'save'}
          </button>
        </div>
      </div>
    </SettingsWrapper>
  )
}

export default Security
