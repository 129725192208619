import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AuthButton from '../../components/auth/AuthButton'
import Input from '../../components/base/Input'
import './style.scss'
import { loginUser } from '../../redux/slices/user'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const SignIn = ({ path }: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [userForm, setUserForm] = useState<any>({
    email: '',
    password: '',
  })
  const setInputValue = (label: string, value: any) => {
    const updatedForm = { ...userForm }
    updatedForm[label] = value
    setUserForm(updatedForm)
  }

  const submitUser = () => {
    dispatch(loginUser(navigate, '/', userForm, t))
  }

  return (
    <div className="auth-form-wrapper bg-gray-50 dark:bg-transparent">
      <ToastContainer theme="dark" autoClose={3000} />

      <div className="text-sm border-gray-300 dark:border-gray-700 border rounded p-5 ">
        <p className="text-2xl text-gray-800 dark:text-gray-200 text-center p-10">
          {t('auth.signIn.description')}{' '}
          <span className="link-in-text " onClick={() => navigate('/sign-up')}>
            {t('auth.signIn.addition')}
          </span>
        </p>
        <div className="flex justify-center">
          <AuthButton signUp={false} path={path} />
        </div>
        <div className="flex justify-center items-center pt-10 pb-3">
          <div className="h-line"></div>
          <div className="px-5 font-medium text-gray">OR</div>
          <div className="h-line"></div>
        </div>
        <form className="p-5">
          <Input
            value={userForm.email}
            setValue={setInputValue}
            key="email"
            name="email"
            type="email"
            label="Email"
            placeholder="Email"
          />
          <Input
            value={userForm.password}
            setValue={setInputValue}
            key="password"
            name="password"
            type="password"
            label="Password"
            placeholder="Password"
          />
          <button onClick={submitUser} type="button" className="btn w-full">
            {t('auth.signIn.action')}
          </button>
        </form>
      </div>
    </div>
  )
}

export default SignIn
