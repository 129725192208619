import { getMe } from '../api'

export const checkAuth = async () => {
  const accessToken = localStorage.getItem('accessToken')
  const refreshToken = localStorage.getItem('refreshToken')

  if (!accessToken || !refreshToken) return false
  try {
    const result = await getMe()
    return result
  } catch (e) {
    console.error('[checkAuth]', e)
  }
}

export default checkAuth
