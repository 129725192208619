import React, { useRef, useState } from 'react'
import ReactSelect from 'react-select'

interface ISelect {
  label: string
  placeholder: string
  options: Array<any>
  name: string
  value: string
  setValue: any
}

const Select = ({ label, placeholder, options, name, value, setValue }: ISelect) => {
  const [placeholderValue, setPlaceholderValue] = useState(placeholder)
  const labelRef: any = useRef()
  const selectRef: any = useRef()

  const hideLabel = () => {
    if (value) return
    labelRef.current.style.visibility = 'hidden'
    setPlaceholderValue(placeholder)
  }
  const showLabel = () => {
    labelRef.current.style.visibility = 'visible'
    setPlaceholderValue('')
  }

  const onChange = (val: any) => {
    setValue(name, val)
  }

  return (
    <div className="pb-6 input-default">
      <label ref={labelRef} className="invisible text-blue-300 dark:text-green-400">
        {label}
      </label>
      <ReactSelect
        ref={selectRef}
        value={value}
        className="basic-single select"
        classNamePrefix="country"
        isClearable={true}
        isSearchable={true}
        options={options}
        onChange={onChange}
        onBlur={hideLabel}
        onFocus={showLabel}
        placeholder={placeholderValue}
      />
    </div>
  )
}

export default Select
