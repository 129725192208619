import React from 'react'
import Logo from '../../Logo'

const AuthHeader = () => {
  return (
    <div className="flex justify-center pt-20 pb-10">
      <Logo />
    </div>
  )
}

export default AuthHeader
