import AxiosLib from 'axios'
import { config } from './config'

const getAxios = (): any => {
  const API_REFRESH_URL = `${config.apiUrl}/api/token/refresh/`
  const LOGIN_URL = '/sign-in'
  const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME || 'accessToken'

  const getRefreshToken = () => {
    if (localStorage[`${TOKEN_NAME}-refresh`]) {
      return localStorage[`${TOKEN_NAME}-refresh`]
    }
    return null
  }

  const axios = AxiosLib.create({
    baseURL: config.apiUrl,
    headers: {
      'content-type': 'application/json',
    },
    responseType: 'json',
  })
  if (localStorage[TOKEN_NAME]) {
    const access = localStorage[TOKEN_NAME]
    axios.defaults.headers.common.Authorization = `Bearer ${access}`
  }
  function createAxiosResponseInterceptor(instance: any) {
    if (instance === undefined) instance = axios
    const interceptor = instance.interceptors.response.use(
      (response: any) => response,
      (error: any) => {
        if (!error.response) return Promise.reject(error)
        error = error.response
        if (error.status !== 401) {
          return Promise.reject(error)
        }
        instance.interceptors.response.eject(interceptor)
        const refresh = getRefreshToken()
        if (!refresh) {
          // Tokens are invalid, removing log data
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          return Promise.reject(error)
        }
        return instance
          .post(API_REFRESH_URL, { refresh: refresh })
          .then((response: any) => {
            const accessToken = response.data.access
            localStorage.setItem(TOKEN_NAME, accessToken)
            error.config.headers['Authorization'] = 'Bearer ' + accessToken
            return instance(error.config)
          })
          .catch((error: any) => {
            localStorage.removeItem(`${TOKEN_NAME}-refresh`)
            localStorage.removeItem(TOKEN_NAME)
            if (window.location.pathname !== LOGIN_URL) {
              window.location.href = LOGIN_URL
            }
            return Promise.reject(error)
          })
      }
    )
  }
  createAxiosResponseInterceptor(axios)
  return axios
}
export default getAxios
