import SettingsWrapper from '../SettingsWrapper'

import React, { useEffect, useRef, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import Loading from '../../Loading'
import { useNavigate } from 'react-router'
import { regenerateApiKey } from '../../../api'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'
import { getMeAction, setUserAction } from '../../../redux/slices/user'

const ApiKey = () => {
  const [regenerateApiKeyLoading, setRegenerateApiKeyLoading] = useState(false)
  const { user } = useSelector((state: RootStateOrAny) => state.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const btnRevealApiKeyRef: any = useRef()
  const pRevealApiKeyRef: any = useRef()

  const revealApiKey = () => {
    btnRevealApiKeyRef.current.style.display = 'none'
    pRevealApiKeyRef.current.style.visibility = 'visible'
  }

  const regenerateApikeyAction = async () => {
    setRegenerateApiKeyLoading(true)
    const response = await regenerateApiKey()
    if (!response.result) {
      setRegenerateApiKeyLoading(false)
      return toast.error("The passwords don't match", {
        position: 'top-center',
      })
    }

    dispatch(setUserAction({ ...user, apiKey: response.apiKey }))
    toast.success('Your API key has been regenerated', {
      position: 'top-center',
    })
    setRegenerateApiKeyLoading(false)
  }

  if (!user) {
    return (
      <div className="col-span-3">
        <p>{'Please, sign in to view your details'}</p>
      </div>
    )
  }

  return (
    <SettingsWrapper title={'API key'}>
      <ToastContainer theme="dark" autoClose={3000} />

      <div className="text-base">
        <div className="flex pb-4">
          <p className="pr-4">{'Your API key'}:</p>
          <button ref={btnRevealApiKeyRef} onClick={revealApiKey} className="btn outlined">
            {'Reveal API key'}
          </button>
          <p ref={pRevealApiKeyRef} className="invisible">
            {user.apiKey}
          </p>
        </div>
        <div>
          <p>
            {'As '}
            <a onClick={() => navigate('/docs')}>{'explained in the docs'}</a>
            {', provide a header of the form'}
          </p>
          <div className="flex">
            <p className="font-semibold">{'apikey: <YOUR_API_KEY>'}&nbsp;</p>
            <p>{'with every request you make to the api'}</p>
          </div>
        </div>
        {regenerateApiKeyLoading ? (
          <Loading text="Regenerating api key" />
        ) : (
          <button onClick={regenerateApikeyAction} className="btn outlined">
            {'Regenerate API key'}
          </button>
        )}
      </div>
    </SettingsWrapper>
  )
}

export default ApiKey
