import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './en.json'
//import es from './es.json'

export const resources = {
  en: { translation: en },
  es: {
    translation: en,
  },
}

let defaultLanguage = 'en'
const userLang = localStorage.getItem('lang')

if (userLang) defaultLanguage = userLang

i18next.use(initReactI18next).init({
  lng: defaultLanguage,
  debug: true,
  resources,
})
