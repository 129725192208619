import SettingsWrapper from '../SettingsWrapper'

import React, { useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { fetchPlans } from '../../../redux/slices/plans'
import { formatDate } from '../../../utils/pipes'
import { cancelSubscription } from '../../../api'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { getMeAction } from '../../../redux/slices/user'
import Loading from '../../Loading'

const Billing = () => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const { user } = useSelector((state: RootStateOrAny) => state.user)
  const { plans, loadingPlans } = useSelector((state: RootStateOrAny) => state.plans)

  const navigate = useNavigate()

  useEffect(() => {
    if (plans.length === 0 && !loadingPlans) dispatch(fetchPlans())
  }, [])

  const getPlanName = () => {
    for (const plan of plans) {
      let name = ''
      if (plan.id === user.subscription?.plan) {
        name = plan.name
        if (user.subscription?.status === 'incomplete')
          name +=
            ' - incomplete | If you already paid this plan, you will see it in less than a minute when reloading the page'
        return name
      }
    }

    return '-'
  }

  const changePlanHandler = async () => {
    /* setLoading(true)
    if (user.subscription) await cancelSubscription()
    await getMeAction()
    toast.info('Please select your new Plan', {
      position: 'top-center',
    }) */
    navigate('/pricing')
  }

  if (loadingPlans || loading) {
    return (
      <div className="content py-10">
        <div className="completion">
          <Loading text="" />
        </div>
      </div>
    )
  }

  if (!user) {
    return <div className="content py-10">{'error'}</div>
  }

  return (
    <SettingsWrapper title={'Profile'}>
      <ToastContainer theme="dark" autoClose={3000} />

      <div className="text-base"></div>
      <p className="font-medium text-gray-100">{'Current plan'}</p>
      <p className="text-base">{getPlanName()}</p>
      <button className="text-center btn my-2" onClick={changePlanHandler}>
        {'Change plan'}
      </button>
      <h1 className="text-2xl py-4">{'Credits'}</h1>

      {!user.characters || user.characters.length === 0 ? (
        <div>{"You don't have characters"}</div>
      ) : (
        <table className="table-auto">
          <thead>
            <tr>
              <th>{'Amount'}</th>
              <th>{'Expire'}</th>
            </tr>
          </thead>
          <tbody>
            {user.characters.map((char) => (
              <tr key={char.expiration}>
                <td>{char.value}</td>
                <td>{formatDate(char.expiration * 1000)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </SettingsWrapper>
  )
}

export default Billing
