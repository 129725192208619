import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Loading from '../components/Loading'
import { setUserAction } from '../redux/slices/user'
import checkAuth from '../utils/auth'

type IAuthMiddleware = {
  children: React.ReactElement
}

const AuthMiddleware: React.FC<IAuthMiddleware> = ({ children }) => {
  // if user in history, log user.
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
    checkAuth()
      .then((res) => {
        if (res?.result) {
          dispatch(setUserAction(res.user))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])
  if (loading) return <Loading text="" />
  return children
}

export default AuthMiddleware
