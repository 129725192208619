/* eslint-disable i18next/no-literal-string */
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Logo = () => {
  const navigate = useNavigate()

  return (
    <h1 onClick={() => navigate('/')} className="logo cursor-pointer shadow-default">
      <span className="text-gray-100">Turing</span>

      <span className="text-green-300 dark:text-green-300">K</span>
      <span className="text-blue-300 dark:text-green-300">i</span>
      <span className="text-red-350 dark:text-green-300">t</span>
    </h1>
  )
}

export default Logo
