import React from 'react'
import Logo from '../Logo'
import './style.scss'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

const Footer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="mt-auto">
      <div className="separator">
        <svg viewBox="0 0 455 32" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M.62 38.176C52.147 32.007 371.072 43.052 454.43.234v37.942c-322.666 2.41-473.936 2.41-453.81 0z"
            fill="#FFF"
          ></path>
        </svg>
      </div>

      <footer
        className="
 py-16 flex justify-between  content align-middle items-center bg-gray-900 text-gray-300 flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row "
      >
        <div className="min-w-full md:min-w-0 lg:min-w-0 xl:min-w-0 2xl:min-w-0 pt-2">
          <p className="text-xl uppercase font-medium text-white pb-3">{t('pages')}</p>
          <ul>
            <li onClick={() => navigate('/about')} className="nav-item pb-1">
              {t('about.about')}
            </li>
            <li onClick={() => navigate('/pricing')} className="nav-item">
              {t('pricing.pricing')}
            </li>
          </ul>
        </div>
        <div className="min-w-full md:min-w-0 lg:min-w-0 xl:min-w-0 2xl:min-w-0 pt-2">
          <p className="text-xl  uppercase font-medium text-white pb-3">{t('legal')}</p>
          <ul>
            <li className="nav-item pb-1">{t('terms')}</li>
            <li className="nav-item">{t('privacyPolicy')}</li>
          </ul>
        </div>
        <div className="min-w-full md:min-w-0 lg:min-w-0 xl:min-w-0 2xl:min-w-0 pt-2">
          <p className="text-xl uppercase font-medium text-white pb-3">{t('contact')}</p>
          <ul>
            <li className="nav-item pb-1">{t('email')}</li>
            <li className="nav-item">{t('twitter')}</li>
          </ul>
        </div>
        <div className="pt-2">
          <div className="pb-3">
            <Logo />
          </div>
          <Trans i18nKey="footer.developedBy"></Trans>
          <br />
          <div className="text-sm pt-2 text-gray-400">{t('copyright')}</div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
