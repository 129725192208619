import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { cancelSubscription, getFreePlan, getPlans } from '../../api'
import { getError } from '../../utils/pipes'

const initialState = {
  plans: [],
  planSelected: null,
  loadingPlans: false,
  loadingMessage: '',
  error: false,
}

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setPlans: (state, { payload }) => {
      state.plans = payload
      state.loadingPlans = false
    },
    setPlanSelected: (state, { payload }) => {
      state.planSelected = payload
    },
    setLoadingPlans: (state, { payload }) => {
      state.loadingPlans = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
    setLoadingMessage: (state, { payload }) => {
      state.loadingMessage = payload
    },
  },
})

export const { reducer, actions } = plansSlice

export default reducer

export const changingPlan = (navigate: any, plan: any, t: any) => async (dispatch: any) => {
  if (plan.name !== 'Free') navigate('/checkout')
  else {
    dispatch(getFreePlanAction(t))
  }
}

export const fetchPlans = () => async (dispatch: any) => {
  dispatch(actions.setLoadingPlans(true))
  const res = await getPlans()
  dispatch(actions.setPlans(res))
  dispatch(actions.setLoadingPlans(false))
}

export const setPlanSelectedAction = (plan: any) => (dispatch: any) => {
  dispatch(actions.setPlanSelected(plan))
}

export const cancelPlan = async () => {
  await cancelSubscription()
}

export const getFreePlanAction = (t: any) => async (dispatch: any) => {
  dispatch(actions.setLoadingPlans(true))
  dispatch(actions.setLoadingMessage('Getting your free plan'))
  const response = await getFreePlan()
  if (!response.result) {
    toast(getError(response.error || 'UNKNOWN_ERROR', t), {
      type: 'error',
      isLoading: false,
      position: 'top-center',
      autoClose: 3000,
    })
  }
  dispatch(actions.setLoadingPlans(false))
}
