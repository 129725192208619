import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import './style.scss'

const Home = () => {
  const navigate = useNavigate()

  const { t } = useTranslation()

  return (
    <>
      <div className="content  bg-gray-900 pd-10 text-gray-300">
        <div className=" pb-5 justify-center dark:text-gray-400 text-sm">
          <div className=" px-15 py-5 md:px-40 md:py-20">
            <h1 className="pb-10">
              {t('home.header1')} <span className="text-green-300"> {t('home.header2')}</span>
            </h1>
            <p className="dark:text-gray-200 text-lg mb-10">{t('pricing.turingKitInfo')}</p>
            <div>
              <button className="btn mr-4" onClick={() => navigate('/demo')}>
                {t('tryForFree')}
              </button>
              <button onClick={() => navigate('/docs')} className="btn outlined">
                {t('learnMore')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="separator">
        <svg viewBox="0 0 455 32" xmlns="http://www.w3.org/2000/svg">
          <path d="M.623 22.445C183.947 47.26 291.633 15.95 454.435 0H.623v22.445z" fillRule="evenodd"></path>
        </svg>
      </div>
      <div className="content grid grid-cols-10 py-20 gap-4">
        <div className="xl:col-span-4 2xl:col-span-4 lg:col-span-4 md:col-span-10 col-span-10 px-5">
          <p className="font-bold text-2xl text-gray-800 dark:text-slate-200 pb-4">
            {t('home.section.section1.header')}
          </p>
          <p className="dark:text-slate-300">{t('home.section.section1.description')}</p>
        </div>
        <div className="xl:col-span-3 2xl:col-span-3 lg:col-span-3 md:col-span-10 col-span-10 border dark:border-transparent border-gray-300 rounded p-5 dark:bg-gray-900 bg-slate-50 drop-shadow-lg dark:drop-shadow-none flex">
          <div className="text-3xl text-center bg-blue-300 dark:bg-green-300 rounded-full w-10 h-10 aspect-square text-slate-50 dark:text-gray-800">
            {t('home.section.section1.block1.symbol')}
          </div>

          <div className="px-4">
            <p className="font-medium text-xl pb-2 text-gray-800 dark:text-slate-200">
              {t('home.section.section1.block1.header')}
            </p>
            <p>{t('home.section.section1.block1.description')}</p>
          </div>
        </div>

        <div className="xl:col-span-3 2xl:col-span-3 lg:col-span-3 md:col-span-10 col-span-10 border dark:border-transparent border-gray-300 rounded p-5 dark:bg-gray-900 bg-slate-50 drop-shadow-lg  dark:drop-shadow-none flex">
          <span className="text-3xl text-center  bg-blue-300 dark:bg-green-300 rounded-full w-10 h-10 aspect-square text-slate-50  dark:text-gray-800">
            {t('home.section.section1.block2.symbol')}
          </span>

          <div className="px-4">
            <p className="font-medium text-xl pb-2 text-gray-800 dark:text-slate-200">
              {t('home.section.section1.block2.header')}
            </p>
            <p>{t('home.section.section1.block2.description')}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
