import Slider from 'rc-slider'
import React from 'react'
import DemoContext from '../../../pages/Demo/DemoContext'
import Info from '../../base/Info'

const Option = ({ optionName, optionText, minVal, maxVal, step, width = '', textAlign = '' }: any) => {
  const { options, updateOptions } = React.useContext(DemoContext)

  const setOptions = (val: any) => {
    const newOptions = { ...options }
    newOptions[optionName] = val
    updateOptions(newOptions)
  }

  return (
    <>
      <div className="font-medium text-gray-800 dark:text-gray-300 flex ">
        {optionName} <Info text={optionText} width={width} textAlign={textAlign} />
      </div>
      <div className="pb-3 flex items-center">
        <Slider min={minVal} max={maxVal} step={step} onChange={setOptions} value={options[optionName]} />
        <span className="p-2">{options[optionName]}</span>
      </div>
    </>
  )
}

export default Option
