import React from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import CloseIcon from '../../../images/icons/CloseIcon'

type Modal = {
  slice: string
  selector: RootStateOrAny
  action: (val: boolean) => void
  title: string
  render: any
}

const Modal = ({ slice, selector, action, render, title }: Modal) => {
  const _slice = useSelector((state: RootStateOrAny) => state[slice])
  const showModal = _slice[selector]
  const dispatch = useDispatch()

  const setShowModal = (val: boolean) => {
    dispatch(action(val))
  }

  return (
    <>
      {showModal ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white dark:bg-gray-900 outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                  <h3 className="text-xl">{title}</h3>

                  <div
                    onClick={() => setShowModal(false)}
                    style={{ display: 'flex' }}
                    className="hover:bg-gray-800 cursor-pointer mr-5"
                  >
                    <CloseIcon />
                  </div>
                </div>
                <div className="relative p-0 flex-auto">{render}</div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default Modal
