import React from 'react'
import { useTranslation } from 'react-i18next'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import SectionWithSidebar from '../../components/base/SectionWithSidebar'
import Authentication from '../../components/docs/Authentication'
import BatchRequests from '../../components/docs/BatchRequests'
import GeneratingText from '../../components/docs/GeneratingText'
import TextGeneration from '../../components/docs/TextGeneration'
import { setCurrentPage } from '../../redux/slices/docs'
import { Pages } from '../../Types'
import Pricing from '../Pricing'

type MenuItem = {
  page: string
  title: string
}

const Docs = () => {
  // DEVELOPER API Authentication Generating Text SEE ALSO Pricing
  const { currentPage } = useSelector((state: RootStateOrAny) => state.docs)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setCurrentPageHandler = (page: string) => {
    dispatch(setCurrentPage(page))
  }

  const getClass = (page: string) => {
    let className = 'text-base  font-medium pb-2'
    if (page === currentPage) className += ' dark:text-green-300 underline'
    else className += ' dark:text-gray-300 dark:hover:text-gray-100 cursor-pointer'
    return className
  }

  const MenuItem = ({ page, title }: MenuItem) => {
    return (
      <p onClick={() => setCurrentPageHandler(page)} className={getClass(page)}>
        {title}
      </p>
    )
  }

  const pages: Pages = {
    'text-generation': <TextGeneration />,
    'batch-requests': <BatchRequests />,
    authentication: <Authentication />,
    'generating-text': <GeneratingText />,
    pricing: <Pricing />,
  }

  return (
    <SectionWithSidebar
      sidebar={
        <div className="pr-5 col-span-1">
          <div className="pb-6">
            <p className="text-sm uppercase font-medium pb-2">{t('docs.overview.title')}</p>
            <MenuItem page="text-generation" title={t('docs.overview.textGeneration.title')} />
            <MenuItem page="batch-requests" title={t('docs.overview.batchRequests.title')} />
          </div>
          <div className="pb-6">
            <p className="text-sm uppercase font-medium pb-2">{t('docs.developerAPI.title')}</p>
            <MenuItem page="authentication" title={t('docs.developerAPI.authentication.title')} />
            <MenuItem page="generating-text" title={t('docs.developerAPI.generatingText.title')} />
          </div>
          <div>
            <p className="text-sm uppercase font-medium pb-2">{t('docs.seeAlso.title')}</p>
            <div onClick={() => navigate('/pricing')}>
              <MenuItem page="pricing" title={t('docs.seeAlso.pricing.title')} />
            </div>
          </div>
        </div>
      }
      section={<div className="pr-5 col-span-4 text-lg flex flex-col items-center">{pages[currentPage]}</div>}
    />
  )
}

export default Docs
