import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import img1 from '../../../images/docs_screenshot.jpg'

const TextGeneration = () => {
  const { t } = useTranslation()
  return (
    <div className=" text-base text-gray-300">
      <Trans>
        <h1 className="pb-6 text-gray-300">{t('docs.overview.textGeneration.title')}</h1>
        <p className="pb-4">{t('docs.overview.textGeneration.description')}</p>
        <div className="grid grid-cols-4 gap-2 pb-4">
          <p className="col-span-1">{t('docs.overview.textGeneration.example.input.title')}</p>
          <p className="col-span-3">{t('docs.overview.textGeneration.example.input.content')}</p>
        </div>
        <div className="grid grid-cols-4 gap-2 pb-4">
          <p className="col-span-1">{t('docs.overview.textGeneration.example.output.title')}</p>
          <p className="col-span-3">{t('docs.overview.textGeneration.example.output.content')}</p>
        </div>
        <p className="pb-4">{t('docs.overview.textGeneration.description2')}</p>
        <img className="pb-4" src={img1} />
        <h1 className="pb-6 text-gray-300">{t('docs.overview.textGeneration.section2.title')}</h1>
        <p className="pb-4">{t('docs.overview.textGeneration.section2.content')}</p>
        <h1 className="pb-6 text-gray-300">{t('docs.overview.textGeneration.section3.title')}</h1>
        <p className="pb-4">{t('docs.overview.textGeneration.section3.content')}</p>
        <h1 className="pb-6 text-gray-300">{t('docs.overview.textGeneration.section4.title')}</h1>
        <p className="pb-4">{t('docs.overview.textGeneration.section4.content')}</p>
        <h1 className="pb-6 text-gray-300">{t('docs.overview.textGeneration.section5.title')}</h1>
        <h2 className="pb-5 text-gray-300">{t('docs.overview.textGeneration.section5.subsection1.title')}</h2>
        <p className="pb-4">{t('docs.overview.textGeneration.section5.subsection1.text')}</p>
        <h2 className="pb-5 text-gray-300">{t('docs.overview.textGeneration.section5.subsection2.title')}</h2>
        <p className="pb-4">{t('docs.overview.textGeneration.section5.subsection2.text')}</p>
        <h2 className="pb-5 text-gray-300">{t('docs.overview.textGeneration.section5.subsection3.title')}</h2>
        <p className="pb-4">{t('docs.overview.textGeneration.section5.subsection3.text1')}</p>
        <p className="pb-4">{t('docs.overview.textGeneration.section5.subsection3.text2')}</p>
        <ul>
          <li>{t('docs.overview.textGeneration.section5.subsection3.li1')}</li>
          <li>{t('docs.overview.textGeneration.section5.subsection3.li2')}</li>
          <li>{t('docs.overview.textGeneration.section5.subsection3.li3')}</li>
          <li>{t('docs.overview.textGeneration.section5.subsection3.li4')}</li>
        </ul>
      </Trans>
    </div>
  )
}

export default TextGeneration
