import React, { SetStateAction, Dispatch } from 'react'
import { IOptions } from '../../Types'

// set the defaults

interface IDemoContext {
  options: IOptions
  updateOptions: Dispatch<SetStateAction<IOptions>>
}
const DemoContext = React.createContext<IDemoContext>({
  options: { length: 100 },
  updateOptions: () => undefined,
})

export default DemoContext
