import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import { useEffect, useState } from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router'
import { centsToUnitString, currencySymbol } from '../../utils/currencies'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '')

const Completion = () => {
  const [error, setError] = useState('')
  const [paymentData, setPaymentData] = useState<any>()
  const navigate = useNavigate()

  useEffect(() => {
    if (!stripePromise) {
      return setError('UNKNOWN ERROR')
    }

    stripePromise
      .then(async (stripe: any) => {
        const url = new URL(window.location.toString())
        const clientSecret = url.searchParams.get('payment_intent_client_secret')
        const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)
        paymentIntent.created = new Date(paymentIntent.created).toLocaleString()
        if (error) setError(error.message)
        else setPaymentData(paymentIntent)
      })
      .catch((e) => {
        console.error('[completion]/stripe-promise', e)
        setError('UNKNOWN_ERROR')
      })
  }, [stripePromise])

  if (error || !paymentData) {
    return <div className="content py-10">{error}</div>
  }

  return (
    <div className="content py-10">
      <div className="completion">
        <div className="icon-lg text-green-300 pb-4">
          <AiFillCheckCircle />
        </div>
        <h1 className="pb-4">{'Payment complete'}</h1>
        <div className="line mb-4"></div>
        <div className="flex justify-between w-full mb-2">
          <div className="col-span-3 text-gray-300">{'Date:'}</div>
          <div className="col-span-3 text-gray-200">{paymentData.created}</div>
        </div>
        <div className="flex justify-between w-full mb-2">
          <div className="col-span-3 text-gray-300">{'Price:'}</div>
          <div className="col-span-3 text-gray-200">
            {centsToUnitString(paymentData.amount)}
            {currencySymbol[paymentData.currency]}
          </div>
        </div>
        <div className="flex justify-between w-full mb-2">
          <div className="col-span-3 text-gray-300">{'Chars:'}</div>
          <div className="col-span-3 text-gray-200">{'2500000'}</div>
        </div>
        <div className="flex mt-4">
          <button onClick={() => navigate('/')} className="text-center btn">
            {'Return to home'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Completion
