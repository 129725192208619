import React from 'react'
import { useNavigate } from 'react-router'
import GoogleIcon from '../../../images/GoogleIcon'
import './style.scss'
import { useDispatch } from 'react-redux'
import { loginGoogle } from '../../../redux/slices/user'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

type AuthButtonProps = {
  signUp: boolean
  path?: string | null
  disabled?: string
}

const AuthButton = ({ signUp, path, disabled = '' }: AuthButtonProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const navigate = useNavigate()

  return (
    <div
      onClick={() =>
        disabled
          ? toast.error(disabled, {
              position: 'top-center',
            })
          : dispatch(loginGoogle(navigate, signUp, path, t))
      }
      className="btn-auth"
    >
      <GoogleIcon />
      <span className="pl-2 font-normal">
        {signUp ? t('auth.registerWith', { app: 'Google' }) : t('auth.continueWith', { app: 'Google' })}
      </span>
    </div>
  )
}

export default AuthButton
