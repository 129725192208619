import React, { useEffect, useRef, useState } from 'react'
import './style.scss'

interface IInput {
  type: string
  label: string
  placeholder: string
  value: string
  setValue: any
  name: string
}

const Input = ({ name, type, label, placeholder, value, setValue }: IInput) => {
  const [placeholderValue, setPlaceholderValue] = useState(placeholder)
  const labelRef: any = useRef()
  const inputRef: any = useRef()

  const hideLabel = () => {
    if (inputRef.current.value) return
    labelRef.current.style.visibility = 'hidden'
    setPlaceholderValue(placeholder)
  }
  const showLabel = () => {
    labelRef.current.style.visibility = 'visible'
    setPlaceholderValue('')
  }

  useEffect(() => {
    if (!inputRef.current.value) return
    showLabel()
  }, [])

  return (
    <div className="pb-6 input-default w-full">
      <label ref={labelRef} className="invisible text-blue-300 dark:text-green-400">
        {label}
      </label>
      <input
        value={value}
        onChange={(e) => setValue(name, e.target.value)}
        ref={inputRef}
        onBlur={hideLabel}
        onFocus={showLabel}
        type={type}
        placeholder={placeholderValue}
      />
    </div>
  )
}

export default Input
