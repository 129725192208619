import React, { useEffect, useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useTranslation } from 'react-i18next'
import Loading from '../Loading'
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux'
import { checkout, continueCheckout } from '../../api'
import PricingPlan from '../PricingPlan'
import { cancelPlan } from '../../redux/slices/plans'
import { getMeAction } from '../../redux/slices/user'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '')
const showDataForTesting = false

const CardDataForTesting = () => {
  if (!showDataForTesting) return <></>
  return (
    <>
      <p>{'4000007240000007'}</p>
      <p>
        {'doble autenticación: '}
        <span>{'4000 0025 0000 3155'}</span>
      </p>
      <p>
        {'tarjeta rechazada con código error:'} <span>{'4000 0000 0000 9995'}</span>
      </p>
    </>
  )
}

const CheckoutStripeForm = () => {
  const stripe = useStripe()
  const elements = useElements()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const cancel = async (e: any) => {
    e.preventDefault()
    await cancelPlan()
    location.reload()
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (elements == null) {
      return
    }
    if (!stripe || !elements) return
    setLoading(true)
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // eslint-disable-next-line camelcase
        return_url: `${window.location.origin}/completion`,
      },
    })
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setLoading(false)
      return <div>{'error: ' + error.type}</div>
    } else {
      setLoading(false)
      return <div>{'error: Unknown error'}</div>
    }
  }

  return (
    <form className="stripe-form" onSubmit={handleSubmit}>
      <CardDataForTesting />
      <PaymentElement className="pb-4" />
      <div className="grid grid-cols-2 gap-2">
        {loading ? (
          <Loading text="" />
        ) : (
          <>
            <button className="btn w-full" type="submit" disabled={!stripe}>
              {t('pricing.plan.buySubscription')}
            </button>
            <button onClick={cancel} className="btn outlined w-full" type="submit" disabled={!stripe}>
              {t('pricing.plan.cancel')}
            </button>
          </>
        )}
      </div>
    </form>
  )
}

const CheckoutStripe = () => {
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState({ isError: false, message: '' })
  const [clientSecret, setClientSecret] = useState('')
  const { planSelected, plans } = useSelector((state: RootStateOrAny) => state.plans)
  const { user, userLoading } = useSelector((state: RootStateOrAny) => state.user)

  const dispatch = useDispatch()

  const getSecret = async () => {
    if (!planSelected.id) setError({ isError: true, message: 'Please select a plan first.' })
    else {
      let checkoutResult
      if (!user.subscription) checkoutResult = await checkout(planSelected.id)
      else checkoutResult = await continueCheckout()
      if (!checkoutResult.result) {
        setError({ isError: true, message: checkoutResult.error })
      } else {
        setError({ isError: false, message: '' })
        setClientSecret(checkoutResult.clientSecret)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    dispatch(getMeAction())
    getSecret()
  }, [])

  if (isLoading || userLoading) return <Loading text="Loading!" />
  if (error.message === 'ALREADY_PENDING_SUBSCRIPTION') {
    return (
      <div>
        <p className="pb-2 text-gray-400">{'You have a pending subscription for this plan:'}</p>
        <div className="w-96">
          <PricingPlan
            key={user.subscription.plan}
            plan={plans.filter((plan) => plan.id === user.subscription.plan)[0]}
          />
        </div>
      </div>
    )
  }
  if (error.isError) return <div>{error.message}</div>

  return (
    <div className="flex justify-center">
      <div className="w-96 mr-8">
        <PricingPlan key={planSelected.id} plan={planSelected} showButton={false} />
      </div>
      <Elements
        stripe={stripePromise}
        options={{ clientSecret: clientSecret, appearance: { theme: 'night', labels: 'floating' } }}
        key={clientSecret}
      >
        <CheckoutStripeForm />
      </Elements>
    </div>
  )
}
export default CheckoutStripe
