import React from 'react'
import Tooltip from '../Tooltip'

const Info = ({ text, width = '', textAlign = '' }: any) => {
  const InfoIcon = () => {
    return (
      <div className="rounded-full w-5 h-5 bg-gray-700 text-gray-200 flex items-center justify-center">?</div>
    )
  }

  return (
    <div className="pl-4 text-sm">
      <Tooltip render={<InfoIcon />} text={text} width={width} textAlign={textAlign} />
    </div>
  )
}

export default Info
