import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import MoonIcon from '../../images/MoonIcon'
import SunIcon from '../../images/SunIcon'
import { getMeAction, logout } from '../../redux/slices/user'
import Logo from '../Logo'
import './style.scss'
//import LanguageSelector from '../base/LanguageSelector'
import { config } from '../../config'
import Select from 'react-dropdown-select'
import { AiFillBook, AiFillCreditCard, AiOutlineKey, AiOutlineUser } from 'react-icons/ai'
import { AiOutlineLogout } from 'react-icons/ai'

const changeTheme = () => {
  if (localStorage.theme === 'dark') {
    localStorage.setItem('theme', '')
    document.documentElement.classList.remove('dark')
  } else {
    localStorage.setItem('theme', 'dark')
    document.documentElement.classList.add('dark')
  }
}

const ToggleTheme = () => {
  if (!config.toggleTheme) return <></>
  return (
    <button type="button" onClick={() => changeTheme()}>
      <span className="dark:hidden">
        <MoonIcon />
      </span>
      <span className="hidden dark:inline">
        <SunIcon />
      </span>
    </button>
  )
}
const userOptions = [
  { label: 'Profile', value: 'profile', icon: <AiOutlineUser /> },
  { label: 'Billing', value: 'billing', icon: <AiFillCreditCard /> },
  { label: 'API Key', value: 'apikey', icon: <AiOutlineKey /> },
  { label: 'Docs', value: 'docs', icon: <AiFillBook /> },
  {
    label: 'signOut',
    value: 'signOut',
    icon: <AiOutlineLogout />,
  },
]
const Nav = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { t } = useTranslation()

  const userOptionsActions = {
    profile: () => navigate('/settings/profile'),
    billing: () => navigate('/settings/billing'),
    apikey: () => navigate('/settings/apikey'),
    docs: () => navigate('/docs'),
    signOut: () => dispatch(logout(navigate)),
  }
  const { user } = useSelector((state: RootStateOrAny) => state.user)
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const isMobile = windowSize.current[0] < 800
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getMeAction())
  }, [location])

  const renderDropdownMenu = () => {
    if (!user) {
      return (
        <div className="relative inline-block text-left">
          <div>
            <button
              type="button"
              className=" border border-gray-300 bg-white dark:bg-gray-800 shadow-sm flex items-center justify-center w-full rounded-md  px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
              id="options-menu"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <svg
                width="20"
                height="20"
                fill="currentColor"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z"></path>
              </svg>
            </button>
          </div>
          <div
            style={{ display: isMenuOpen ? 'block' : 'none' }}
            className="absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg dark:bg-gray-800 ring-1 ring-black ring-opacity-5"
          >
            <div className="py-1 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <a
                href="/pricing"
                className="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                role="menuitem"
              >
                <span className="flex flex-col">
                  <span>{t('pricing.pricing')}</span>
                </span>
              </a>
              <a
                href="/about"
                className="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                role="menuitem"
              >
                <span className="flex flex-col">
                  <span>{t('about.about')}</span>
                </span>
              </a>
              <a
                href="/sign-in"
                className="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                role="menuitem"
              >
                <span className="flex flex-col">
                  <span>{t('auth.signIn.title')}</span>
                </span>
              </a>
              <a
                href="/sign-up"
                className="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                role="menuitem"
              >
                <span className="flex flex-col">
                  <span>{t('auth.signUp.title')}</span>
                </span>
              </a>
              <a
                href="/demo"
                className="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                role="menuitem"
              >
                <span className="flex flex-col">
                  <span>
                    {'✨ '}
                    {t('generateText')}
                  </span>
                </span>
              </a>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="relative inline-block text-left">
          <div>
            <button
              type="button"
              className=" border border-gray-300 bg-white dark:bg-gray-800 shadow-sm flex items-center justify-center w-full rounded-md  px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
              id="options-menu"
            >
              {t('app.title')}
              <svg
                width="20"
                height="20"
                fill="currentColor"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z"></path>
              </svg>
            </button>
          </div>
          <div
            style={{ display: isMenuOpen ? 'block' : 'none' }}
            className="absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg dark:bg-gray-800 ring-1 ring-black ring-opacity-5"
          >
            <div className="py-1 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <a
                href="/about"
                className="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                role="menuitem"
              >
                <span className="flex flex-col">
                  <span>{t('about.about')}</span>
                </span>
              </a>
              <a
                href="/demo"
                className="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                role="menuitem"
              >
                <span className="flex flex-col">
                  {user.picture ? (
                    <img referrerPolicy="no-referrer" src={user.picture} alt="" className="avatar"></img>
                  ) : (
                    <div className="no-avatar">{user.firstName}</div>
                  )}
                </span>
              </a>
            </div>
          </div>
        </div>
      )
    }
  }

  const renderMenu = () => {
    if (!user) {
      return (
        <>
          <li onClick={() => navigate('/pricing')} className="nav-item">
            {t('pricing.pricing')}
          </li>
          <li onClick={() => navigate('/about')} className="nav-item">
            {t('about.about')}
          </li>
          <li onClick={() => navigate('/docs')} className="nav-item">
            {t('docs.title')}
          </li>
          <li onClick={() => navigate('/sign-in')} className="nav-item">
            {t('auth.signIn.title')}
          </li>
          <li onClick={() => navigate('/sign-up')} className="nav-item">
            {t('auth.signUp.title')}
          </li>
          <button onClick={() => navigate('/demo')} className="btn">
            {t('generateText')}
          </button>
        </>
      )
    }
    return (
      <>
        <li onClick={() => navigate('/pricing')} className="nav-item">
          {t('pricing.pricing')}
        </li>
        <li onClick={() => navigate('/about')} className="nav-item">
          {t('about.about')}
        </li>
        <li onClick={() => navigate('/docs')} className="nav-item">
          {t('docs.title')}
        </li>
        <button onClick={() => navigate('/demo')} className="btn">
          {t('generateText')}
        </button>
        <div className="text-2xl text-gray-600">|</div>

        <div className="user-options">
          <Select
            options={userOptions}
            values={[userOptions[0]]}
            contentRenderer={() => (
              <li className="nav-item hover:opacity-80 focus:outline-none">
                {user.picture ? (
                  <img referrerPolicy="no-referrer" src={user.picture} alt="" className="avatar"></img>
                ) : (
                  <div className="no-avatar">{user.firstName[0]}</div>
                )}
              </li>
            )}
            itemRenderer={({ item, methods }) => (
              <div className="flex pl-4 py-3" onClick={() => methods.addItem(item)}>
                <h3 className="text-xl pr-2">{item.icon}</h3>
                {item.label}
              </div>
            )}
            onChange={(val) => userOptionsActions[val[0].value]()}
          />
        </div>
      </>
    )
  }

  return (
    <nav className="py-5 flex justify-between content align-middle items-center bg-gray-900">
      <Logo />
      <div className="flex items-center dark:text-gray-200 text-slate-700 font-medium text-base">
        <ul className="flex items-center space-x-8">{isMobile ? renderDropdownMenu() : renderMenu()}</ul>
        <div className="flex items-center border-l border-slate-200 ml-6 pl-6 dark:border-slate-800">
          <ToggleTheme />
          <div className="relative inline-block text-left">{/* <LanguageSelector />*/}</div>
        </div>
      </div>
    </nav>
  )
}
export default Nav
