import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import AuthButton from '../../components/auth/AuthButton'
import Input from '../../components/base/Input'
import { countries } from '../../static/countries'
import './style.scss'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from '../../components/base/Select'
import { createUser } from '../../redux/slices/user'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const SignUp = ({ path }: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userLoading, verifyEmailView } = useSelector((state: RootStateOrAny) => state.user)
  const [userForm, setUserForm] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    country: {},
    company: '',
    terms: false,
  })
  const [step, setStep] = useState('first')

  const setSelectValue = (label: string, value: any) => {
    const updatedForm = { ...userForm }
    updatedForm[label] = value
    setUserForm(updatedForm)
  }

  const setInputValue = (label: string, value: any) => {
    const updatedForm = { ...userForm }
    updatedForm[label] = value
    setUserForm(updatedForm)
  }

  const submitUser = () => {
    if (!userForm.terms)
      return toast.error('Please accept the terms and conditions', {
        position: 'top-center',
      })
    const userFormTransformed = { ...userForm }
    userFormTransformed.country = userFormTransformed.country.value
    dispatch(createUser(userFormTransformed, t))
  }
  if (verifyEmailView) {
    return (
      <div className={'auth-form-wrapper bg-gray-50 dark:bg-transparent'}>
        <h1 className="text-center pb-4">{'Welcome to TuringKit!'}</h1>
        <div className="text-sm border-gray-300 dark:border-gray-700 border rounded p-5 ">
          <p className="text-lg pb-2 dark:text-gray-300">
            {'Please verify your email address to complete your registration'}
          </p>
          <p>
            {'Return to'} <a href="/">{'home'}</a>
          </p>
        </div>
      </div>
    )
  }
  return (
    <div className={'auth-form-wrapper bg-gray-50 dark:bg-transparent'}>
      <ToastContainer theme="dark" autoClose={4000} />

      <form className="text-sm border-gray-300 dark:border-gray-700 border rounded p-5 ">
        <fieldset disabled={userLoading}>
          {step === 'first' ? (
            <>
              <p className="text-2xl text-gray-800 dark:text-gray-200 text-center p-10 pb-4">
                {t('auth.signUp.description')}{' '}
                <span className="link-in-text " onClick={() => navigate('/sign-in')}>
                  {t('auth.signUp.addition')}
                </span>
              </p>
              <div className="flex justify-center pt-6">
                {userForm.terms ? (
                  <AuthButton signUp={true} path={path} />
                ) : (
                  <AuthButton signUp={true} path={path} disabled={'Please accept the terms and conditions'} />
                )}
              </div>
              <div className="flex justify-center items-center pt-10 pb-3">
                <div className="h-line"></div>
                <div className="px-5 font-medium text-gray">OR</div>
                <div className="h-line"></div>
              </div>

              <div className="p-5">
                <Input
                  value={userForm.email}
                  setValue={setInputValue}
                  key="email"
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="Email"
                />
                <Input
                  value={userForm.password}
                  setValue={setInputValue}
                  key="password"
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="Password"
                />
                <div className="flex items-center mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    onChange={(e) => setInputValue('terms', e.target.checked)}
                    checked={userForm.terms}
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    {'Accept terms and conditions'}
                  </label>
                </div>

                <button onClick={() => setStep('second')} className="btn w-full">
                  {t('auth.signUp.action')}
                </button>
              </div>
            </>
          ) : (
            <>
              <p className="text-2xl text-gray-800 dark:text-gray-200 text-center p-10 pb-4">
                {t('auth.signUp.welcome')}
              </p>
              <div className="p-5">
                <Input
                  value={userForm.email}
                  setValue={setInputValue}
                  key="email"
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="Email"
                />
                <Input
                  value={userForm.password}
                  setValue={setInputValue}
                  key="password"
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="Password"
                />
                <Input
                  value={userForm.firstName}
                  setValue={setInputValue}
                  key="firstName"
                  name="firstName"
                  type="text"
                  label="First Name"
                  placeholder="First Name"
                />
                <Input
                  value={userForm.lastName}
                  setValue={setInputValue}
                  key="lastName"
                  name="lastName"
                  type="text"
                  label="Last Name"
                  placeholder="Last Name"
                />
                <Select
                  placeholder={'Select Country'}
                  label={'country'}
                  options={countries}
                  name="country"
                  value={userForm.country}
                  setValue={setSelectValue}
                />
                <Input
                  value={userForm.company}
                  setValue={setInputValue}
                  key="company"
                  name="company"
                  type="text"
                  label="Company (optional)"
                  placeholder="Company (optional)"
                />
                <div className="flex items-center mb-4">
                  <input
                    onChange={(e) => setInputValue('terms', e.target.checked)}
                    type="checkbox"
                    value=""
                    checked={userForm.terms}
                  />
                  <label
                    htmlFor="default-checkbox"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    {'Accept terms and conditions'}
                  </label>
                </div>
                <button onClick={submitUser} type="button" className="btn w-full">
                  {t('auth.signUp.action')}
                </button>
              </div>
            </>
          )}
        </fieldset>
      </form>
    </div>
  )
}

export default SignUp
