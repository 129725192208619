import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}
import { connectAuthEmulator } from 'firebase/auth'

const FirebaseApp = initializeApp(firebaseConfig)

export const FirebaseAuthErrors: any = {
  'auth/email-already-exists': 'EMAIL_ALREADY_EXISTS',
  'auth/invalid-email': 'INVALID_EMAIL',
  'auth/user-not-found': 'USER_NOT_FOUND',
  'auth/wrong-password': 'INCORRECT_PASSWORD',
}

export const auth = getAuth(FirebaseApp)
if (window.location.hostname === 'localhost') {
  connectAuthEmulator(auth, 'http://localhost:9099/')
  console.log('auth emulator localhost, config:', firebaseConfig)
}
export const provider = new GoogleAuthProvider()
