import React from 'react'

const SettingsWrapper = (props: any) => {
  return (
    <div className="px-8 col-span-4 text-lg flex flex-col text-gray-300">
      <h1 className="text-2xl pb-4">{props.title}</h1>
      <div>{props.children}</div>
    </div>
  )
}

export default SettingsWrapper
