import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showAuthModal: false,
}

export const demoSlice = createSlice({
  name: 'demo',
  initialState,
  reducers: {
    setShowAuthModal: (state, { payload }) => {
      state.showAuthModal = payload
    },
  },
})

export const { reducer, actions } = demoSlice

export const setShowAuthModal = (showModal: boolean) => (dispatch: any) => {
  dispatch(actions.setShowAuthModal(showModal))
}

export default reducer
