import React from 'react'
import { useTranslation } from 'react-i18next'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { cancelPlan, changingPlan, getFreePlanAction, setPlanSelectedAction } from '../../redux/slices/plans'
import { getMeAction, updateUserBeforeChangingPlan } from '../../redux/slices/user'
import { IPricingPlan } from '../../Types'
import { centsToUnitString, currencySymbol } from '../../utils/currencies'
import Loading from '../Loading'
import { confirmAlert } from 'react-confirm-alert'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import 'react-toastify/dist/ReactToastify.css'

interface IPricingPlanProps {
  plan: IPricingPlan
  showButton?: boolean
}

const PricingPlan = ({ plan, showButton = true }: IPricingPlanProps) => {
  const { t } = useTranslation()
  const { user, userLoading } = useSelector((state: RootStateOrAny) => state.user)
  const { loadingPlans, plans } = useSelector((state: RootStateOrAny) => state.plans)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const continueCheckout = () => {
    if (!user.subscription) dispatch(getMeAction())
    dispatch(setPlanSelectedAction(plans.filter((plan) => plan.id === user.subscription.plan)[0]))
    if (window.location.pathname === '/checkout') {
      window.location.reload()
    } else navigate('/checkout')
  }
  const cancel = async () => {
    await cancelPlan()
    location.reload()
  }

  const handleGetPlan = () => {
    if (user.subscription?.status === 'active') {
      if (user.subscription.id === plan.id)
        return toast.info('You are already on this plan', {
          position: 'top-center',
        })
      confirmAlert({
        title: 'Confirm to submit',
        message: 'You already have a subscription, it will be deleted, are you sure?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              await cancelPlan()
              dispatch(updateUserBeforeChangingPlan(navigate, plan, t))
            },
          },
          {
            label: 'No',
            onClick: () => alert('Click No'),
          },
        ],
      })
    } else {
      dispatch(setPlanSelectedAction(plan))
      if (plan.name !== 'Free') navigate('/checkout')
      else {
        dispatch(getFreePlanAction(t))
      }
    }
  }

  const getButtons = () => {
    if (!user) {
      return (
        <button onClick={() => navigate('/sign-in')} className="btn w-full ">
          {t('auth.signUp.action')}
        </button>
      )
    }
    if (user.subscription?.status === 'incomplete') {
      return (
        <div>
          <button onClick={continueCheckout} className="btn w-full mb-4">
            {t('pricing.plan.continueCheckout')}
          </button>
          <button onClick={cancel} className="btn outlined w-full">
            {t('pricing.plan.cancel')}
          </button>
        </div>
      )
    }
    return (
      <button onClick={handleGetPlan} className="btn w-full">
        {t('pricing.plan.getPlan')}
      </button>
    )
  }

  if (loadingPlans || userLoading) return <Loading text="" />

  return (
    <div
      className={`
    text-gray-600 dark:text-gray-400 bg-slate-50 dark:bg-gray-800 border-gray-300
    dark:border-gray-700 border rounded flex flex-col justify-between 
    ${user?.subscription?.id === plan.id ? 'plan-selected' : ''}
    `}
    >
      <div className="p-5 border-b border-gray-500 dark:border-green-400">
        <p className="text-xl text-gray-800 dark:text-gray-200">{plan.name}</p>
        <p className="text-2xl text-gray-800 dark:text-gray-200 font-semibold">
          {currencySymbol[plan.defaultPriceData.currency]}
          {centsToUnitString(plan.defaultPriceData.unitAmountDecimal)}
          <span className="text-gray-500 dark:text-gray-400 text-sm font-normal">
            {t('pricing.priceTime')}
          </span>
        </p>
      </div>
      <div className="p-5">
        <p className="text-center">
          <strong>{plan.unitLabel.toLocaleString()}</strong> {t('pricing.plan.montlyCharactersText')}
          <br />
          <br /> <br /> &nbsp;
          <strong>
            {plan.extra
              ? t('pricing.plan.extraPriceDetails', {
                  currency: currencySymbol[plan.defaultPriceData.currency],
                  price: plan.extra.price,
                  amount: plan.extra.amount.toLocaleString(),
                })
              : ''}
          </strong>
        </p>
      </div>

      {showButton ? <div className="p-5">{getButtons()}</div> : <></>}
    </div>
  )
}

export default PricingPlan

/**
 * 
 El login redirije si se le pasa una página por parámetro.
 getPlan , guarda en el slice el plan, y redirije a vista checkout.
 * 
 */
