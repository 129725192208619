import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import PricingPlan from '../../components/PricingPlan'
import { fetchPlans, setPlanSelectedAction } from '../../redux/slices/plans'
import { IPricingPlan } from '../../Types'
import './style.scss'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import Loading from '../../components/Loading'
import { getMeAction } from '../../redux/slices/user'

const Pricing = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { plans, loadingPlans, loadingMessage, planSelected } = useSelector(
    (state: RootStateOrAny) => state.plans
  )

  const { user } = useSelector((state: RootStateOrAny) => state.user)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (plans.length < 1 && !loadingPlans) dispatch(fetchPlans())
  })

  useEffect(() => {
    if (!user) dispatch(getMeAction())
    if (user?.subscription?.status === 'incomplete') {
      if (!planSelected) {
        const plan = plans.filter((plan: IPricingPlan) => plan.id === user.subscription.plan)[0]
        dispatch(setPlanSelectedAction(plan))
      }
    }
    setLoading(false)
  }, [])

  if (loadingPlans || loading)
    return (
      <div className="content pd-10">
        <div className="text-gray-800 pb-5 justify-center dark:text-gray-400 text-sm">
          <div className="px-40 py-20"></div> <Loading text={loadingMessage} />
        </div>
      </div>
    )

  if (user?.subscription?.status === 'incomplete') {
    return (
      <div className="content pt-20">
        <div className=" pb-5 justify-center">
          <p className="pb-2 text-gray-400">{'You have a pending subscription for this plan:'}</p>
          <div className="w-96">
            <PricingPlan
              key={user.subscription.plan}
              plan={plans.filter((plan: IPricingPlan) => plan.id === user.subscription.plan)[0]}
            />
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <ToastContainer theme="dark" autoClose={3000} />

      <div className="content md:pd-0 md:pd-10">
        <div className="text-gray-800 pb-0 md:pb-5 justify-center dark:text-gray-400 text-sm">
          <div className="px-15 py-5 md:px-40 md:py-20">
            <div className="w-fill">
              <h1 className="pb-5 text-center text-gray-800 dark:text-gray-100">{t('pricing.pricing')}</h1>
              <p className="pb-8 text-gray-600 text-center text-lg dark:text-gray-300">
                {t('pricing.pricesInUSD')}
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-10 py-5 md:py-10 ">
              {plans.length > 0 ? (
                plans.map((plan: IPricingPlan) => <PricingPlan key={plan.id} plan={plan} />)
              ) : (
                <Loading text="" />
              )}
            </div>
            <p className=" dark:text-gray-300 text-base mb-10">
              {t('pricing.turingKitInfo')}
              &nbsp;<span>{t('app.learnMoreIn')}</span>&nbsp;
              <a onClick={() => navigate('/docs')} className="font-medium text-blue-400">
                {t('app.theDocs')}
              </a>
              <br />
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pricing
