import React from 'react'
import { useEffect, useState } from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import { verifyEmail } from '../../api'
import Loading from '../../components/Loading'

const Wrapper = (props: any) => {
  return (
    <>
      <div className="content pt-20">{props.children}</div>
    </>
  )
}

function VerifyEmail() {
  const [messageBody, setMessageBody] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)

  const handleError = (err) => {
    setError(err)
    setLoading(false)
  }

  const verifyEmailAction = async () => {
    const url = new URL(window.location.toString())
    const token = url.searchParams.get('token')
    if (!token) return handleError('INVALID_URL')
    const verifyEmailResponse = await verifyEmail(token)
    if (!verifyEmailResponse.result) return handleError(verifyEmailResponse.error)
    setMessageBody("Congratulations, you're account has been validated")
    setLoading(false)
  }

  useEffect(() => {
    verifyEmailAction()
  }, [])

  if (loading)
    return (
      <Wrapper>
        <Loading text="Loading" />
      </Wrapper>
    )
  if (error)
    return (
      <Wrapper>
        <h1>{'Something is wrong...'}</h1>
        <a href="/">{'home'}</a>
        <div id="messages" role="alert">
          {error}
        </div>
      </Wrapper>
    )
  return (
    <Wrapper>
      <div className="flex pb-6 items-center  pb-4">
        <div className=" text-green-300 text-3xl pr-2">
          <AiFillCheckCircle />
        </div>
        <p className="text-xl text-gray-100">{'Email verified'}</p>
      </div>
      <div className="pb-2 text-base text-gray-300" id="messages" role="alert">
        {messageBody}
      </div>
      <a href="/sign-in">{'Sign In'}</a>
    </Wrapper>
  )
}

export default VerifyEmail
