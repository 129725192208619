export const currencySymbol: any = {
  usd: '$',
  eur: '€',
}

export const centsToUnitString = (units: number) => {
  return (units / 100).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
