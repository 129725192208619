import React from 'react'
import BounceLoader from 'react-spinners/BounceLoader'

type LoadingProps = {
  text: string
}
const Loading = ({ text }: LoadingProps) => {
  return (
    <div className="flex justify-center items-center flex-col">
      <div className="pb-4">
        <BounceLoader color="#89ED98" />
      </div>
      <p className="text-base"> {text}</p>
    </div>
  )
}
export default Loading
