import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentPage: 'text-generation',
}

export const docsSlice = createSlice({
  name: 'docs',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload
    },
  },
})

export const { reducer, actions } = docsSlice

export const setCurrentPage = (currentPage: string) => (dispatch: any) => {
  if (currentPage === 'pricing') return
  dispatch(actions.setCurrentPage(currentPage))
}

export default reducer
