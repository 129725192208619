import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import SectionWithSidebar from '../../components/base/SectionWithSidebar'
import ApiKey from '../../components/settings/Apikey'
import Billing from '../../components/settings/Billing'
import Profile from '../../components/settings/Profile'
import Security from '../../components/settings/Security'
import { getMeAction } from '../../redux/slices/user'
import { Pages } from '../../Types'

type MenuItem = {
  page: string
  title: string
}

const Settings = () => {
  const [currentPage, setCurrentPage] = useState('profile')
  const { t } = useTranslation()
  const navigate = useNavigate()

  const params: any = useParams()

  useEffect(() => {
    getMeAction()
    setCurrentPage(params.page)
  })

  const setCurrentPageHandler = (page: string) => {
    navigate('/settings/' + page)
  }

  const getClass = (page: string) => {
    let className = 'text-base p-2 '
    if (page === currentPage)
      className += `dark:text-green-300 font-medium bg-green-100
    bg-opacity-5 border-green-300 border-solid border-r-2`
    else className += 'dark:text-gray-300 dark:hover:text-green-300 cursor-pointer'
    return className
  }

  const MenuItem = ({ page, title }: MenuItem) => {
    return (
      <p onClick={() => setCurrentPageHandler(page)} className={getClass(page)}>
        {title}
      </p>
    )
  }

  const pages: Pages = {
    profile: <Profile />,
    billing: <Billing />,
    apikey: <ApiKey />,
    security: <Security />,
  }

  return (
    <SectionWithSidebar
      sidebar={
        <div className="pr-6 col-span-1">
          <div className="pb-6 border-solid dark:border-gray-700 border rounded">
            <p className="bg-gray-900 bg-opacity-30 p-2 text-sm uppercase font-medium py-4 text-gray-200">
              {t('docs.overview.title')}
            </p>
            <hr className=" dark:border-gray-700"></hr>
            <MenuItem page="profile" title={t('settings.menu.profile.title')} />
            <MenuItem page="billing" title={t('settings.menu.billing.title')} />
            <MenuItem page="apikey" title={t('settings.menu.apiKey.title')} />
            <MenuItem page="security" title={t('settings.menu.security.title')} />
          </div>
        </div>
      }
      section={pages[currentPage]}
    />
  )
}

export default Settings
