import SettingsWrapper from '../SettingsWrapper'

import React, { useEffect, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import InputOnlyRead from '../../base/InputOnlyRead'
import { resendEmail } from '../../../api'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { countries } from '../../../static/countries'
import { getError } from '../../../utils/pipes'

const Profile = () => {
  const [loading, setLoading] = useState(false)

  const { user } = useSelector((state: RootStateOrAny) => state.user)
  const { t } = useTranslation()

  if (!user) {
    return (
      <div className="col-span-3">
        <p>{'Please, sign in to view your details'}</p>
      </div>
    )
  }

  const setValue = () => {
    return null
  }

  const resendEmailAction = async () => {
    setLoading(true)
    const resendEmailResponse = await resendEmail()
    if (!resendEmailResponse.result) {
      setLoading(false)
      toast.error(getError(resendEmailResponse.error, t), {
        position: 'top-center',
      })
      return
    }
    setLoading(false)
    toast.info('The email has been resent, please check your inbox', {
      position: 'top-center',
    })
  }

  return (
    <SettingsWrapper title={t('settings.menu.profile.title')}>
      <ToastContainer theme="dark" autoClose={3000} />
      <div className="text-base">
        {user.verifiedEmail ? (
          ''
        ) : (
          <div className="mb-6">
            <div className="flex mb-2 items-center">
              <div className="rounded-full w-5 h-5 bg-red-700 text-gray-200 flex items-center justify-center mr-2">
                !
              </div>
              <p className="text-red-300 mr-4">{'Email not verified'}</p>
            </div>

            <button onClick={resendEmailAction} type="button" className="btn outlined">
              {'Resend verification email'}
            </button>
          </div>
        )}
        <div className="flex w-full">
          <InputOnlyRead value={user.firstName} setValue={setValue} key="firstName" label="Name" />
          {user?.lastname ? (
            <InputOnlyRead value={user.lastName} setValue={setValue} label="Last Name" />
          ) : (
            ''
          )}
        </div>
        {user.country ? (
          <InputOnlyRead
            label={'country'}
            value={countries.filter((e) => e.value === user.country)[0].label}
            setValue={setValue}
          />
        ) : (
          ''
        )}
        {user.company ? (
          <InputOnlyRead value={user.company.label} setValue={setValue} label="Company (optional)" />
        ) : (
          ''
        )}
        <InputOnlyRead value={user.email} setValue={setValue} label="Email" />
      </div>
    </SettingsWrapper>
  )
}

export default Profile
