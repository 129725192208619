import React from 'react'

const Tooltip = ({ render, text, width = '', textAlign = 'text-center' }: any) => {
  return (
    <>
      <div className="relative">
        <div className="group cursor-pointer relative text-center">
          {render}
          <div
            style={width ? { width: width } : {}}
            className={`w-40 opacity-0 bg-black text-white ${textAlign} text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 px-3 pointer-events-none`}
          >
            {text}
          </div>
        </div>
      </div>
    </>
  )
}

export default Tooltip
