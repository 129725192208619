import React from 'react'

import RobotImage from '../../images/robot-render.png'
import { Trans } from 'react-i18next'

const About = () => {
  return (
    <>
      <div className="content grid grid-cols-1 md:grid-cols-2 py-20 gap-4">

        <div className="px-5">
          <p className="dark:text-slate-300">
            <Trans i18nKey="about.content"></Trans>
          </p>
        </div>
      </div>
    </>
  )
}

export default About
