import React, { useRef, useState } from 'react'
import CloseIcon from '../../images/icons/CloseIcon'
import CopyIcon from '../../images/icons/CopyIcon'
import './style.scss'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import DemoContext from './DemoContext'
import { IOptions } from '../../Types'

import Option from '../../components/dashboard/Option'
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux'
import Modal from '../../components/base/Modal'
import { setShowAuthModal } from '../../redux/slices/demo'
import SignIn from '../SignIn'
import Tooltip from '../../components/base/Tooltip'
import SectionWithSidebar from '../../components/base/SectionWithSidebar'
import { useNavigate } from 'react-router'
import { getText } from '../../api'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { config } from '../../config'
import getAxios from '../../axiosInstance'
import { getError } from '../../utils/pipes'
import { logout } from '../../redux/slices/user'

const Demo = () => {
  const { user } = useSelector((state: RootStateOrAny) => state.user)
  const [options, setOptions] = useState<IOptions>({ length: 100, topK: 10, topP: 0.9, temperature: 1.0 })
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const updateOptions = (_options: IOptions) => {
    setOptions(_options)
  }

  const [isLoadingText, setIsLoadingText] = useState(false)
  const textEditor: any = useRef()
  const textEditorPreview: any = useRef()
  const closeButton: any = useRef()
  const copyButton: any = useRef()
  const [abortController, setAbortController] = useState(new AbortController())

  const hideTextPreview = () => {
    textEditor.current.focus()
  }

  const showTextPreview = (event: any) => {
    if (
      event &&
      textEditor.current.innerText.length >= 0 &&
      !(event.target.innerText.length === 1 && event.key === 'Backspace')
    ) {
      textEditorPreview.current.style.display = 'none'
      closeButton.current.style.visibility = 'visible'
      copyButton.current.style.visibility = 'visible'
    } else {
      textEditorPreview.current.style.display = 'block'
      closeButton.current.style.visibility = 'hidden'
      copyButton.current.style.visibility = 'hidden'
    }
  }

  const removeText = () => {
    if (isLoadingText) return
    textEditor.current.innerText = ''
    showTextPreview(null)
    hideTextPreview()
  }

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(textEditor.current.innerText)
  }

  const generate = async () => {
    if (!user) {
      dispatch(setShowAuthModal(true))
      return
    }
    setIsLoadingText(true)

    const userText = textEditor.current.innerText

    textEditorPreview.current.style.display = 'none'
    closeButton.current.style.display = 'flex'
    copyButton.current.style.display = 'flex'
    textEditor.current.style.pointerEvents = 'none'

    let x = 0
    let dataChunk = ''

    const req = {
      prompt: userText,
      length: options.length.toString(),
      // eslint-disable-next-line camelcase
      top_k: options.topK.toString(),
      // eslint-disable-next-line camelcase
      top_p: options.topP.toString(),
      temperature: options.temperature.toString(),
      streamResponse: 'True',
      type: 'text',
    }
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms))
    const makeReq = async () => {
      getAxios()
        .post(`${config.apiUrl}/ai/`, req, {
          onDownloadProgress: async (progressEvent) => {
            let result: string = progressEvent.currentTarget.response

            try {
              const r = JSON.parse(result)
              if (r?.result === false) {
                setAbortController(new AbortController())

                throw Error('repeat')
                toast(getError(r.error || 'UNKNOWN_ERROR', t), {
                  type: 'error',
                  isLoading: false,
                  position: 'top-center',
                  autoClose: 3000,
                })
                textEditor.current.style.pointerEvents = ''

                setIsLoadingText(false)
                setAbortController(new AbortController())
                return
              }
            } catch (e) {
              if (e?.message === 'repeat') {
                console.log('re-sending')
                await sleep(1000)
                await makeReq()
                return
              } else {
                if (result === 'Unauthorized') {
                  dispatch(logout(null))
                  toast('Your session is expired. Please log in again', {
                    type: 'info',
                    isLoading: false,
                    position: 'top-center',
                    autoClose: 3000,
                  })
                  dispatch(setShowAuthModal(true))

                  return
                } else {
                  console.info('[debug] checking exception | !authErr && !repeat')
                }
              }
            }

            const _x = result.length
            console.log(x, result.length)
            console.log(result)
            result = result.substring(x, result.length)
            console.log(result)
            if (result.includes('}{')) {
              result = result.replaceAll('}{', '},{')
              result = '[' + result + ']'
              x = _x
              result = JSON.parse(result)
              for (const re of result) dataChunk += JSON.parse(JSON.stringify(re)).text
              textEditor.current.innerHTML = '<span class="text-ai-background">' + dataChunk + '</span>'
            } else {
              x = _x
              dataChunk += JSON.parse(result).text
              textEditor.current.innerHTML = '<span class="text-ai-background">' + dataChunk + '</span>'
            }
          },
          signal: abortController.signal,
        })
        .catch((e: any) => {
          console.warn('JSON parse:', e)
          if (e?.data !== 'Unauthorized') {
            makeReq()
          }
        })
        .finally(() => {
          setIsLoadingText(false)
          textEditorPreview.current.style.display = 'none'
          closeButton.current.style.visibility = 'visible'
          copyButton.current.style.visibility = 'visible'
          setAbortController(new AbortController())
        })
    }
    makeReq()
  }

  return (
    <DemoContext.Provider value={{ options, updateOptions }}>
      <ToastContainer theme="dark" autoClose={3000} />

      {!user ? (
        <Modal
          slice="demo"
          selector="showAuthModal"
          action={setShowAuthModal}
          render={<SignIn path={null} />}
          title={t('auth.authIsRequired')}
        />
      ) : (
        ''
      )}
      <SectionWithSidebar
        sidebar={
          <div className="pr-5 col-span-1 text-sm">
            <p className="font-semibold text-gray-800 dark:text-gray-100 pb-2 text-lg">
              {t('app.generateOptions')}
            </p>
            <p className="pb-3 text-gray-800 dark:text-gray-300">
              <span>{t('app.learnMoreIn')}</span>&nbsp;
              <a onClick={() => navigate('/docs')}>{t('app.theDocs')}</a>
            </p>
            <Option
              optionName="length"
              optionText={`
                This is a maximum number of characters to generate.
              For technical reasons, fewer will usually be generated. 
              Generation will also be paused when the neural network thinks 
              it's reached a good place to end the text`}
              minVal={10}
              maxVal={1000}
              step={10}
              width="300px"
              textAlign="text-left"
            />
            {!showAdvancedSettings ? (
              <p
                onClick={() => setShowAdvancedSettings(true)}
                className="text-green-300 cursor-pointer hover:underline"
              >
                {'Advanced settings ►'}
              </p>
            ) : (
              <div>
                <p
                  onClick={() => setShowAdvancedSettings(false)}
                  className="text-green-300 cursor-pointer hover:underline pb-4"
                >
                  {'Advanced settings ▼'}
                </p>
                <Option
                  optionName="temperature"
                  optionText={t('app.params.temperature.description', {
                    value: options.temperature,
                    defaultValue: '10',
                    minVal: 10,
                    maxVal: 100,
                  })}
                  minVal={10}
                  maxVal={100}
                  width="300px"
                  textAlign="text-left"
                />
                <Option
                  optionName="topK"
                  optionText={t('app.params.topK.description', {
                    value: options.topK,
                    defaultValue: options.topK,
                    min: 10,
                    max: 1000,
                  })}
                  minVal={10}
                  maxVal={100}
                  width="300px"
                  textAlign="text-left"
                />
                <Option
                  optionName="topP"
                  optionText={t('app.params.topP.description', {
                    value: options.topK,
                    defaultValue: options.topK,
                  })}
                  minVal={10}
                  maxVal={100}
                  width="300px"
                  textAlign="text-left"
                />
              </div>
            )}
          </div>
        }
        section={
          <div className="pr-5 col-span-3 text-lg flex flex-col items-center">
            <div ref={textEditorPreview} className="text-container absolute" onClick={hideTextPreview}>
              <div className="text-previsualizer">
                <div>
                  {t('app.textPreview')}

                  <br></br>
                  <a>{t('app.tryAnExample')}</a>
                </div>
              </div>
            </div>
            <div
              ref={textEditor}
              onKeyDown={showTextPreview}
              className="text-container text-editable"
              contentEditable="true"
            ></div>

            <div className="flex justify-between text-container-actions px-14">
              <div></div>
              {!isLoadingText ? (
                <button onClick={generate} className="btn">
                  {t('app.generateText')}
                </button>
              ) : (
                <button disabled={true} className="btn-disabled">
                  {t('app.generatingText')}
                </button>
              )}
              <div className="flex">
                <button
                  disabled={isLoadingText}
                  ref={closeButton}
                  onClick={removeText}
                  className="icon-action-container dark:hover:bg-gray-900 mr-1 tooltip"
                >
                  <Tooltip render={<CloseIcon />} text={t('tooltip.clear')} />
                </button>
                <button
                  disabled={isLoadingText}
                  ref={copyButton}
                  onClick={copyToClipboard}
                  className="icon-action-container dark:hover:bg-gray-900"
                >
                  <Tooltip render={<CopyIcon />} text={t('tooltip.copy')} />
                </button>
              </div>
            </div>
          </div>
        }
      />
    </DemoContext.Provider>
  )
}

export default Demo
