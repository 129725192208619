import React, { useEffect } from 'react'
import './App.scss'
import Home from './pages/Home'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Demo from './pages/Demo'
import Pricing from './pages/Pricing'
import SignUp from './pages/SignUp'
import DefaultLayout from './layouts/DefaultLayout'
import AuthLayout from './layouts/AuthLayout'
import AuthMiddleware from './middleware/AuthMiddleware'
import About from './pages/About'
import SignIn from './pages/SignIn'
import Docs from './pages/Docs'
import Checkout from './pages/Checkout'
import Completion from './pages/Completion'
import { config } from './config'
import VerifyEmail from './pages/VerifyEmail'
import Settings from './pages/Settings'

const getTheme = () => {
  if (localStorage.getItem('theme') === 'dark') document.documentElement.classList.add('dark')
  else document.documentElement.classList.remove('dark')
}

const App = () => {

  useEffect(() => {
    getTheme()
    if (!config.toggleTheme) document.documentElement.classList.add('dark')
  }, [])



  return (
    <Router>
      <div className="bg-gray-200 dark:bg-gray-800 flex flex-col min-h-screen">
        <Routes>
          <Route
            element={
              <AuthMiddleware>
                <DefaultLayout />
              </AuthMiddleware>
            }
          >
            <Route path="/" element={<Home />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/about" element={<About />} />
            <Route path="/docs" element={<Docs />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/completion" element={<Completion />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/settings/:page?" element={<Settings />} />
          </Route>
          <Route
            element={
              <AuthMiddleware>
                <AuthLayout />
              </AuthMiddleware>
            }
          >
            <Route path="/sign-up" element={<SignUp path="/" />} />
            <Route path="/sign-in" element={<SignIn path="/" />} />
          </Route>
        </Routes>
      </div>
    </Router>
  )
}

export default App
