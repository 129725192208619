import { configureStore } from '@reduxjs/toolkit'
import { demoSlice } from './slices/demo'
import { docsSlice } from './slices/docs'
import { plansSlice } from './slices/plans'
import { userSlice } from './slices/user'

export const store = configureStore({
  reducer: {
    plans: plansSlice.reducer,
    user: userSlice.reducer,
    demo: demoSlice.reducer,
    docs: docsSlice.reducer,
  },
})
