import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

const Authentication = () => {
  const { t } = useTranslation()
  return (
    <div className=" text-gray-300">
      <h1 className="pb-6 text-gray-300">{t('docs.overview.authentication.title')}</h1>
      <Trans>
        <p className="pb-4">{t('docs.overview.authentication.phrase1')}</p>
        <p className="pb-4">{t('docs.overview.authentication.phrase2')}</p>
        <p className="pb-4">{t('docs.overview.authentication.phrase3')}</p>
        <p className="p-2 bg-gray-900 text-gray-300 mb-3 text-xs">
          <code>{t('docs.overview.authentication.code1')}</code>
          <br />
          <code>{t('docs.overview.authentication.code2')}</code>
          <br />
          <code>{t('docs.overview.authentication.code3')}</code>
          <br />
          <code>{t('docs.overview.authentication.code4')}</code>
        </p>
      </Trans>
      <h2 className="pb-5 text-gray-300">{t('docs.overview.authentication.section2.title')}</h2>
      <p className="pb-4">{t('docs.overview.authentication.section2.phrase1')}</p>
    </div>
  )
}

export default Authentication
