import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import './style.scss'

const GeneratingText = () => {
  const { t } = useTranslation()
  return (
    <Trans>
      <div className=" text-base text-gray-300">
        <h1 className="pb-6 text-gray-300">{t('docs.overview.generatingText.section1.title')}</h1>
        <p className="pb-4">{t('docs.overview.generatingText.section1.phrase1')}</p>
        <p className="pb-4">{t('docs.overview.generatingText.section1.phrase2')}</p>
        <p className="pb-4">{t('docs.overview.generatingText.section1.phrase3')}</p>
        <h2 className="pb-5 text-gray-300">{t('docs.overview.generatingText.section2.title')}</h2>
        <p className="pb-4">{t('docs.overview.generatingText.section2.phrase1')}</p>
        <p className="pb-4">{t('docs.overview.generatingText.section2.phrase2')}</p>
        <table className="table-auto  w-full mb-4 text-sm">
          <thead>
            <tr>
              <th>{t('docs.overview.generatingText.tables.table1.titles.title1')}</th>
              <th>{t('docs.overview.generatingText.tables.table1.titles.title2')}</th>
              <th>{t('docs.overview.generatingText.tables.table1.titles.title3')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('docs.overview.generatingText.tables.table1.tr1.td1')}</td>
              <td>{t('docs.overview.generatingText.tables.table1.tr1.td2')}</td>
              <td>{t('docs.overview.generatingText.tables.table1.tr1.td3')}</td>
            </tr>
            <tr>
              <td>{t('docs.overview.generatingText.tables.table1.tr2.td1')}</td>
              <td>{t('docs.overview.generatingText.tables.table1.tr2.td2')}</td>
              <td>{t('docs.overview.generatingText.tables.table1.tr2.td3')}</td>
            </tr>
            <tr>
              <td>{t('docs.overview.generatingText.tables.table1.tr3.td1')}</td>
              <td>{t('docs.overview.generatingText.tables.table1.tr3.td2')}</td>
              <td>{t('docs.overview.generatingText.tables.table1.tr3.td3')}</td>
            </tr>
            <tr>
              <td>{t('docs.overview.generatingText.tables.table1.tr4.td1')}</td>
              <td>{t('docs.overview.generatingText.tables.table1.tr4.td2')}</td>
              <td>{t('docs.overview.generatingText.tables.table1.tr4.td3')}</td>
            </tr>
            <tr>
              <td>{t('docs.overview.generatingText.tables.table1.tr5.td1')}</td>
              <td>{t('docs.overview.generatingText.tables.table1.tr5.td2')}</td>
              <td>{t('docs.overview.generatingText.tables.table1.tr5.td3')}</td>
            </tr>
            <tr>
              <td>{t('docs.overview.generatingText.tables.table1.tr6.td1')}</td>
              <td>{t('docs.overview.generatingText.tables.table1.tr6.td2')}</td>
              <td>{t('docs.overview.generatingText.tables.table1.tr6.td3')}</td>
            </tr>
            <tr>
              <td>{t('docs.overview.generatingText.tables.table1.tr7.td1')}</td>
              <td>{t('docs.overview.generatingText.tables.table1.tr7.td2')}</td>
              <td>{t('docs.overview.generatingText.tables.table1.tr7.td3')}</td>
            </tr>
          </tbody>
        </table>
        <h2 className="pb-5 text-gray-300">{t('docs.overview.generatingText.section2.title1')}</h2>
        <table className="table-auto  w-full mb-4 text-sm">
          <thead>
            <tr>
              <th>{t('docs.overview.generatingText.tables.table2.titles.title1')}</th>
              <th>{t('docs.overview.generatingText.tables.table2.titles.title2')}</th>
              <th>{t('docs.overview.generatingText.tables.table2.titles.title3')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('docs.overview.generatingText.tables.table2.tr1.td1')}</td>
              <td>{t('docs.overview.generatingText.tables.table2.tr1.td2')}</td>
              <td>{t('docs.overview.generatingText.tables.table2.tr1.td3')}</td>
            </tr>
            <tr>
              <td>{t('docs.overview.generatingText.tables.table2.tr2.td1')}</td>
              <td>{t('docs.overview.generatingText.tables.table2.tr2.td2')}</td>
              <td>{t('docs.overview.generatingText.tables.table2.tr2.td3')}</td>
            </tr>
          </tbody>
        </table>
        <h2 className="pb-5 text-gray-300">{t('docs.overview.generatingText.section2.title2')}</h2>
        <p className="p-2 bg-gray-900 text-gray-300 mb-3 text-xs">
          <code>{'{'}</code>
          <br />
          <code className="pl-4">{'"prompt": {'}</code>
          <br />
          <code className="pl-8">{'"text": "Hello world!"'}</code>
          <br />
          <code className="pl-4">{'},'}</code>
          <br />
          <code className="pl-4">{'"length": 500'}</code>
          <br />
          <code>{'}'}</code>
        </p>
        <h2 className="pb-5 text-gray-300">{t('docs.overview.generatingText.section2.title3')}</h2>
        <p className="p-2 bg-gray-900 text-gray-300 mb-3 text-xs">
          <code>{'{'}</code>
          <br />
          <code className="pl-4">{'"prompt": {'}</code>
          <br />
          <code className="pl-8">{'"text": "Hello world!"'}</code>
          <br />
          <code className="pl-8">{'"isContinuation": true'}</code>
          <br />
          <code className="pl-4">{'},'}</code>
          <br />
          <code className="pl-4">{'"length": 500'}</code>
          <code className="pl-4">{'"streamResponse": true'}</code>
          <code className="pl-4">{'"forceNoEnd": true'}</code>
          <code className="pl-4">{'"topP": 0.8'}</code>
          <code className="pl-4">{'"temperature": 1.1'}</code>
          <br />
          <code>{'}'}</code>
        </p>
        <h2 className="pb-5 text-gray-300">{t('docs.overview.generatingText.section2.title4')}</h2>
        <p className="pb-4">{t('docs.overview.generatingText.section2.phrase3')}</p>
        <ul className="list-disc pl-8">
          <li className="pb-4">{t('docs.overview.generatingText.section2.list.li1')}</li>
          <li className="pb-4">{t('docs.overview.generatingText.section2.list.li2')}</li>
          <li className="pb-4">{t('docs.overview.generatingText.section2.list.li3')}</li>
        </ul>
        <h2 className="pb-5 text-gray-300">{t('docs.overview.generatingText.section2.title5')}</h2>
        <p className="pb-4">{t('docs.overview.generatingText.section2.phrase4')}</p>
        <table className="table-auto w-full mb-4 text-sm">
          <thead>
            <tr className="mt-2">
              <th>{t('docs.overview.generatingText.tables.table3.titles.title1')}</th>
              <th>{t('docs.overview.generatingText.tables.table3.titles.title2')}</th>
              <th>{t('docs.overview.generatingText.tables.table3.titles.title3')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('docs.overview.generatingText.tables.table3.tr1.td1')}</td>
              <td>{t('docs.overview.generatingText.tables.table3.tr1.td2')}</td>
              <td>{t('docs.overview.generatingText.tables.table3.tr1.td3')}</td>
            </tr>
            <tr>
              <td>{t('docs.overview.generatingText.tables.table3.tr2.td1')}</td>
              <td>{t('docs.overview.generatingText.tables.table3.tr2.td2')}</td>
              <td>{t('docs.overview.generatingText.tables.table3.tr2.td3')}</td>
            </tr>
          </tbody>
        </table>
        <h2 className="pb-5 text-gray-300">{t('docs.overview.generatingText.section2.title6')}</h2>
        <p className="p-2 bg-gray-900 text-gray-300 mb-3 text-xs">
          <code>{'{'}</code>
          <br />
          <code className="pl-4">{'"data": {'}</code>
          <br />
          <code className="pl-8">{'"text": " run the command below in the root directory of your"'}</code>
          <br />
          <code className="pl-8">{'"isFinalChunk": true'}</code>
          <br />
          <code className="pl-8">{'"reachedEnd": false'}</code>
          <br />
          <code className="pl-4">{'},'}</code>
          <br />
          <code>{'}'}</code>
        </p>
        <p className="pb-4">{t('docs.overview.generatingText.section2.phrase5')}</p>
        <h2 className="pb-5 text-gray-300">{t('docs.overview.generatingText.section2.title7')}</h2>
        <p className="pb-4">{t('docs.overview.generatingText.section2.phrase6')}</p>
        <p className="pb-4">{t('docs.overview.generatingText.section2.phrase7')}</p>
        <p className="p-2 bg-gray-900 text-gray-300 mb-3 text-xs">
          <code>{'{ "data": { "text": " run the command below", "isFinalChunk": false } }'}</code>
          <br />
          <code>{'{ "data": { "text": " in the root directory of your", "isFinalChunk": false } }'}</code>
          <br />
          <code>{'{ "data": { "text": "", "isFinalChunk": true, "reachedEnd": false } }'}</code>
          <br />
        </p>
        <p className="pb-4">{t('docs.overview.generatingText.section2.phrase8')}</p>
        <h2 className="pb-5 text-gray-300">{t('docs.overview.generatingText.section2.title8')}</h2>
        <p className="pb-4">{t('docs.overview.generatingText.section2.phrase9')}</p>
        <p className="pb-4">{t('docs.overview.generatingText.section2.phrase10')}</p>
        <p className="p-2 bg-gray-900 text-gray-300 mb-3 text-xs">
          <code>{'"🤔".length === 2       // Bad!'}</code>
          <br />
          <code>{'[..."🤔"].length === 1  // Correct number of code points'}</code>
          <br />
          <code>{'"🤔"[0] // renders �       Incomplete code point'}</code>
          <br />
          <code>{'[..."🤔"][0] === "🤔"   // First code point, as desired'}</code>
        </p>
      </div>
    </Trans>
  )
}

export default GeneratingText
