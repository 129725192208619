import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

const BatchRequests = () => {
  const { t } = useTranslation()
  return (
    <div className="text-base text-gray-300">
      <h1 className="pb-6 text-gray-300">{t('docs.overview.batchRequests.title')}</h1>
      <Trans>
        <p className="pb-4">{t('docs.overview.batchRequests.text1')}</p>
      </Trans>
      <p className="pb-4">{t('docs.overview.batchRequests.text2')}</p>
      <p className="pb-4">{t('docs.overview.batchRequests.text3')}</p>
      <h1 className="pb-6 text-gray-300">{t('docs.overview.batchRequests.section2.title')}</h1>
      <Trans>
        <p className="pb-4">{t('docs.overview.batchRequests.section2.text1')}</p>
        <p className="pb-4">{t('docs.overview.batchRequests.section2.text2')}</p>
        <p className="pb-4">{t('docs.overview.batchRequests.section2.text3')}</p>
      </Trans>
      <h1 className="pb-6 text-gray-300">{t('docs.overview.batchRequests.section3.title')}</h1>
      <Trans>
        <p className="pb-4">{t('docs.overview.batchRequests.section3.text1')}</p>
        <p className="pb-4">{t('docs.overview.batchRequests.section3.text2')}</p>
      </Trans>
      <h1 className="pb-6 text-gray-300">{t('docs.overview.batchRequests.section4.title')}</h1>
      <p className="pb-4">{t('docs.overview.batchRequests.section4.text1')}</p>
      <h1 className="pb-6 text-gray-300">{t('docs.overview.batchRequests.section5.title')}</h1>
      <p className="pb-4">{t('docs.overview.batchRequests.section5.text1')}</p>
    </div>
  )
}

export default BatchRequests
